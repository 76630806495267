.ModalFilterAdmin {
	.modal,
	.modal-body,
	.modal-footer,
	.modal-header,
	.modal-content,
	p {
		background-color: var(--c1);
        color: var(--text) !important;
	}
	h1, input {
		color: var(--text);
	}
}
