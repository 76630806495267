.HeaderAdmin {
    h1 {
        color: var(--c2-light);
        font-size: 1.875rem;
    }

    h2 {
        margin-top: -10px;
        color: var(--text);
    }
}