@charset "UTF-8";
@use "sass:math";

.PageNotFound {
	width: 60%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;

	.content,
	.image {
		display: flex;
		flex-flow: wrap column;
		gap: 1rem;
	}

	.content {
		width: percentage(math.div(4, 12));

		h1,
		.text,
		button {
			margin: 0 !important;
			font-size: 1.125rem !important;
			font-weight: 400;
			color: var(--text) !important;

			b {
				font-weight: 700;
			}
		}

		button {
			width: fit-content !important;
			padding: 0.75rem 1.25rem !important;
			display: flex !important;
			border-radius: 0.625rem;
			border: 0.0625rem solid #f774b9;
			background-color: #ff97c9;
		}
	}

	.image {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
}
