.HomeAdmin {
	padding: 1rem;
	--plyr-color-main: var(--c2);
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: center;
	justify-content: flex-start;
	gap: 2rem;
	color: var(--text);

	.row,
	.col {
		margin: 0 !important;
		padding: 0 !important;
	}

	.card-principal {
		// margin-top: 60px;
	}

	.card-principal.card, .card-secundario.card {
		width: 100%;
		// padding: 1rem 1rem 0 0;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-end;
		justify-content: space-between;
		gap: 2.5rem;
		border: none;
		border-radius: 0.75rem;
		background-color: var(--c3);
		color: var(--c1);
		box-shadow: none;

		.text-content {
			display: flex;
			flex-flow: wrap column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0.5rem;
			margin: 0.5rem;
			margin-top: 1.5rem;
			h1,
			p {
				color: var(--text);
			}
		}

		button,
		.btn {
			width: 100%;
			margin: 0 !important;
			border-color: var(--c2) !important;
			font-size: 0.875rem;
			color: var(--c1-dark) !important;
			background-color: var(--c2) !important;
			box-shadow: none !important;

			&:focus,
			&:hover {
				border-color: var(--c2) !important;
				color: var(--text) !important;
				background-color: var(--c2) !important;
				box-shadow: none !important;
			}
		}

		&.card-secundario {
			background-color: var(--c2);
			align-items: inherit;
			gap: inherit;

			.card-title > h2,
			h2.text-info {
				color: #96dd1e !important;
			}

			.card-body {
				margin: 30px;
				border-radius: 12px;
				background-color: var(--c3);
				color: var(--text);
			}
		}
	}

	.dashboard-geral {
		// padding: 1rem;
		// --plyr-color-main: var(--c2);
		// width: 100%;
		// display: flex;
		// flex-flow: wrap column;
		// align-items: center;
		// justify-content: flex-start;
		// gap: 2rem;
		// color: var(--text);

		.row,
		.col {
			margin: 0 !important;
			padding: 0 !important;
		}

		.card {
			width: 100%;
			position: relative;
			overflow: hidden;
			display: flex;
			flex-flow: wrap column;
			// align-items: flex-end;
			justify-content: space-between;
			gap: 2.5rem;
			border: none;
			border-radius: 0.75rem;
			background-color: var(--c3);
			color: var(--c1);
			box-shadow: none;

			.text-content {
				display: flex;
				flex-flow: wrap column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 0.5rem;
				margin: 0.5rem;
				margin-top: 1.5rem;
				h1,
				p {
					color: var(--text);
				}
			}

			button,
			.btn {
				width: 100%;
				margin: 0 !important;
				border-color: var(--c2) !important;
				font-size: 0.875rem;
				color: var(--c1-dark) !important;
				background-color: var(--c2) !important;
				box-shadow: none !important;

				&:focus,
				&:hover {
					border-color: var(--c2) !important;
					color: var(--text) !important;
					background-color: var(--c2) !important;
					box-shadow: none !important;
				}
			}

			&.card-principal {
				// margin-top: 60px;

				.card-title > h2,
				h2.text-info {
					color: var(--text) !important;
				}

				.card-body {
					border-radius: 12px;
					background-color: var(--c3);
					color: var(--text);
				}
			}
		}
	}
}
