.CardFinishPayments {
	min-height: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	font-size: 1.125rem;
	font-weight: 400;
	color: var(--text);
	.card {
		color: #ffffff;
		background-color: var(--c0-dark);
		box-shadow: 0 0 2rem 0 #44443c;

		.card-header {
			border-bottom: none;
			background-color: var(--c0-dark);

			.title {
				color: var(--white);
			}
		}

		.card-body {
			.image {
				background: #fff;
				border-radius: 50%;
				padding: 0.625rem 0.625rem;
				display: flex;
				align-items: center;
				justify-content: center;
				bottom: 0.1875rem;
			}

			.band-card {
				margin-top: 3.675rem;
			}

			input {
				width: 100%;
				color: #fff;
				border-color: #8b8a79;
				background-color: #8b8a79;
				font-size: 1.275rem;
			}

			button {
				font-size: 1.125rem;
				color: var(--c1-dark);
				border-color: var(--c2);
				background-color: var(--c2);
			}
			button:hover {
				color: var(--c2);
				border-color: var(--c2);
				background-color: var(--c1);
			}

			form .fieldset input:focus,
			form .fieldset select:focus,
			form .fieldset .input:focus,
			form .fieldset .select:focus {
				border-color: #8b8a79;
				background-color: #23221f !important;
			}
		}

		.price-wrapper {
			display: inline-flex;
			align-items: center;
			gap: 1rem;

			.price {
				font-size: 2.5rem;
			}
		}
	}
}
