.Breadcrumb {
	margin: 0 0 1.75rem 0 !important;
	padding: 0 !important;

	.breadcrumb {
		margin: 0 !important;
		padding: 0 !important;
		display: flex;
		flex-flow: nowrap row;
		align-items: center;
		justify-content: flex-start;
		gap: 0.5em;
		background-color: transparent !important;

		li {
			&.breadcrumb-item {
				display: flex;
				flex-flow: nowrap row;
				align-items: center;
				justify-content: flex-start;
				gap: 0.5em;
				line-height: 1 !important;
				font-size: 0.875rem !important;
				color: var(--text);

				span {
					line-height: 1rem !important;
					font-size: 1rem !important;
					font-weight: 600;
				}

				&:not(:first-child) {
					&::before {
						content: ">";
						font-family: "Font Awesome 5 Free";
						font-weight: bold;
						color: inherit;
					}
				}

				a {
					text-decoration: none;
					color: inherit;
				}
			}
		}
	}
}
