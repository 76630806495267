.container-termo {
	background-color: var(--c1);
	div {
		background-color: var(--c1);
		font-size: 1.3rem;
		font-family: Arial, Helvetica, sans-serif;
		p {
			padding: 1rem;
			margin: 1px 0;
			strong {
				color: var(--c16-light);
				font-weight: bold;
			}
			// color: var(--c16-light);
		}
		h2 {
			margin: 1px 0;
			padding: 1rem;
			color: var(--c16-light);
		}

		.ordem {
			margin-left: 1rem;
		}

		.privacy-policy {
			h1 {
				margin-top: 1rem;
				text-align: center;
				color: var(--c16-light);
			}

			h3 {
				padding: 1rem;
				font-size: larger;
				color: var(--c16-light);
			}

			ul {
				li {
					color: var(--c16-light);
					margin-bottom: 1rem;
				}
			}

			ol {
				li {
					color: var(--c16-light);
				}
			}
		}

		.marcar {
			span {
				display: inline;
				margin: 0;
				padding: 0 1em;
				line-height: 2rem;
				background-color: var(--c2-dark);
			}
		}
	}
}
