.IndicatorFeeling {
	width: 100%;

	.card {
		.card-body {
			padding: 1.25rem 1.875rem;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		&.options-list {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 1rem;
			gap: 1rem;
		}
	}

	.bg-theme-gray {
		background-color: var(--c3);
		box-shadow: none;
	}

	.badge {
		width: 100%;
		padding: 1rem;
		font-size: 1.125rem;
		text-transform: none;
		border-radius: 1.875rem;
		color: var(--white);
		font-weight: 600;
		opacity: 0.3;

		&.selected,
		&.none-selected {
			opacity: 1;
		}
	}

	.ind-nivel-3 {
		background-color: #c8ff6b;
		width: 1.5625rem;
		height: 1.5625rem;
		border-radius: 100%;
		margin-right: 0.625rem;
	}

	.ind-nivel-2 {
		background-color: #beff50;
		width: 1.5625rem;
		height: 1.5625rem;
		border-radius: 100%;
		margin-right: 0.625rem;
	}

	.ind-nivel-1 {
		width: 1.5625rem;
		height: 1.5625rem;
		margin-right: 0.625rem;
		border-radius: 100%;
		background-color: #96dd1e;
	}

	.options-container {
		position: relative;
		width: 100%;
		height: auto;

		&.auto {
			height: auto;
		}
	}
}

@media (min-width: 48rem) and (max-width: 120rem) {
	.IndicatorFeeling {
		.col-md-4,
		.col-md-8 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

@media (max-width: 60rem) {
	.IndicatorFeeling {
		ul {
			&.options-list {
				grid-template-columns: 1fr;
			}
		}
	}
}
