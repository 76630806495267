.radio-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.75rem;

	input[type="radio"] {
		width: 0;
		height: 0;
		position: absolute;
		visibility: hidden;

		&:checked {
			& ~ label > .radio {
				border-color: var(--c4);
				color: var(--white);
				background-color: var(--c4);

				.icon {
					opacity: 1;
				}
			}
		}
	}

	label {
		position: relative;
		display: contents;
		user-select: none;
		cursor: pointer;
		text-align: center;
		font-family: inherit;
		font-size: 0.875rem;
		font-weight: 400;
		color: var(--white);
	}

	.radio {
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border-width: 0.125rem;
		border-style: solid;
		border-color: rgba(white, 0.5);
		line-height: 1;
		font-size: 1.25rem;
		color: var(--c2);
		transition-property: border-color, background-color;
		transition-duration: 0.15s;
		transition-timing-function: linear;

		.icon {
			opacity: 0;
			width: 1rem;
			height: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&:hover {
		.radio {
			border-color: rgba(white, 0.75);
		}
	}
}

@media (max-width: 56.25rem) {
	.radio-wrapper {
		display: flex;
		flex-flow: wrap column;
	}
}
