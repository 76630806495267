.account-recovery {
	--shadow-color: 54deg 13% 10%;
	--bs-sm: 0.0187rem 0.0313rem 0.0437rem hsl(var(--shadow-color) / 0.34),
		0.025rem 0.05rem 0.0625rem -0.075rem hsl(var(--shadow-color) / 0.34),
		0.0625rem 0.125rem 0.1563rem -0.1563rem hsl(var(--shadow-color) / 0.34);
	--bs-md: 0.0187rem 0.0313rem 0.0437rem hsl(var(--shadow-color) / 0.29),
		0.0437rem 0.0813rem 0.1062rem -0.0375rem hsl(var(--shadow-color) / 0.29),
		0.0813rem 0.1625rem 0.2062rem -0.075rem hsl(var(--shadow-color) / 0.29),
		0.1625rem 0.325rem 0.4063rem -0.1187rem hsl(var(--shadow-color) / 0.29),
		0.3125rem 0.625rem 0.7875rem -0.1563rem hsl(var(--shadow-color) / 0.29);

	width: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 2rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white);
	background-color: var(--c1-dark);

	.card {
		will-change: transform;
		width: fit-content;
		padding: 2rem;
		position: relative;
		transform: translateY(0);
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		background-color: var(--c3-dark);
		box-shadow: var(--bs-sm);
		transition-property: transform, box-shadow;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 0.15s;

		.badge {
			font-size: 0.75rem;
			color: var(--black);
			background-color: var(--c2);
			user-select: none;
		}

		.title,
		.text {
			width: 100%;
			text-align: center;
		}

		.title {
			font-size: 1.5rem;
			color: var(--white);
		}

		.text {
			font-size: 1.125rem;
		}

		form {
			width: 100%;
			height: auto;
			margin: 0;
			padding: 0;
			display: flex;
			flex-flow: wrap column;
			gap: 1.5rem;

			.fieldset {
				width: 100%;
				display: flex;
				flex-flow: wrap column;
				justify-content: flex-start;
				gap: 0.75rem;

				label {
					width: 100%;
					margin: 0;
					padding: 0;
					position: relative;
					display: inline-block;
					user-select: none;
					cursor: pointer;
					font-size: 1.125rem;
				}

				.error {
					font-size: 0.875rem;
					text-align: left;
					top: -0.625rem;
					color: var(--c19);
					margin-bottom: 0.1875rem;
				}

				input {
					margin: 0;
				}

				input,
				select,
				.input,
				.select {
					width: 100%;
					max-width: 100%;
					padding: 0.75rem 1rem;
					border-radius: 0.5rem;
					border: 0.125rem solid var(--white);
					font-size: 0.875rem;
					color: var(--white) !important;
					caret-color: var(--white) !important;
					background-color: var(--c1-dark) !important;
					transition: all 0.35s ease-in;
					max-height: 3.625rem;

					&:focus {
						border-color: var(--c2);
						background-color: var(--c3) !important;
					}

					&:-webkit-autofill,
					&:-webkit-autofill:hover,
					&:-webkit-autofill:focus,
					&:-webkit-autofill:active {
						color: var(--white) !important;
						-webkit-text-fill-color: var(--white) !important;
						background-color: var(--c2) !important;
						box-shadow: 0 0 0 1.875em var(--c2) inset !important;
					}

					&:disabled {
						opacity: 0.3;
					}

					&.invalid {
						border: 0.125rem solid #c54f4f;
					}
				}

				.help-text {
					width: 100%;
					display: inline-block;
					user-select: none;
					font-size: 0.75rem;
				}
			}

			.btn {
				font-family: "Futura PT", sans-serif;
				font-size: 1.125rem;
			}

			.btn-primary {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				background-color: var(--c2);
				border-color: var(--c2);
			}

			.btn-primary:hover {
				background-color: var(--c3);
				border-color: var(--c2);
			}

			.btn-secondary:hover {
				background-color: var(--c3);
				border-color: var(--c1-light);
				color: var(--c1-light);
			}
		}

		.row {
			margin: 0 !important;
		}

		&:focus-within,
		&:hover {
			transform: translateY(-0.125rem);
			box-shadow: var(--bs-md);
		}
	}
}
