.CardFeeling {
	width: 100%;
	padding: 3rem 2.875rem;
	position: relative;
	display: flex;
	flex-flow: wrap column;
	font-size: 1.375rem;
	color: var(--c1);
	background: var(--c3);
	border-radius: 0.75rem;
	color: var(--text);

	.content {
		display: flex;
		flex-flow: wrap row;
		gap: 2rem;

		.text {
			width: 100%;
		}
	}

	.options {
		width: 100%;
		margin: 4.125rem 0 0 0;
		display: flex;
		flex-flow: wrap row;
		align-items: stretch;
		justify-content: flex-start;
		gap: 1.9375rem;

		.option {
			width: 10.75rem;
			height: 10.125rem;
			display: flex;
			flex-flow: wrap column;
			gap: 1rem;
			border-radius: 0.75rem;
			background: var(--c2);
			justify-content: center;
			align-items: center;
			border: none;

			.description {
				color: var(--c1-dark);
				font-size: 1.25rem;
			}
		}
	}
}

@media (max-width: 64rem) {
	.CardFeeling {
		.options {
			display: flex;
			justify-content: center;
		}
	}
}
