.to-do-avoid-steps-content {
	display: flex;
	flex-flow: wrap row;
	gap: 1.5rem;

	.list {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;

		padding: 2rem 1.75rem;
		position: relative;
		display: flex;
		flex-flow: wrap;
		align-items: center;
		justify-content: flex-start;
		gap: 0.5rem;
		border-radius: 0.75rem;
		background-color: var(--c3);

		.item {
			width: 100%;
			display: flex;
			flex-flow: wrap row;
			align-items: center;
			justify-content: flex-start;
			gap: 1rem;
			font-size: 1.125rem;
			font-weight: 400;

			.label {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				display: flex;
			}

			.radios-container {
				width: fit-content;
				padding: 0 1rem;
				display: flex;
				flex-flow: nowrap row;
				align-items: stretch;
				justify-content: center;
				gap: 1rem;

				.radio-wrapper {
					position: relative;

					input[type="radio"] {
						width: 0;
						height: 0;
						margin: 0;
						position: absolute;
						visibility: hidden;

						&:checked {
							& ~ label {
								border-color: var(--list-main-color);
								color: var(--c3);
								background-color: var(--list-main-color);
							}
						}
					}

					label {
						width: 2rem;
						height: 2rem;
						margin: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						border: 0.0625rem solid var(--white-transparent);
						cursor: pointer;
						line-height: 1;
						font-size: 0.75rem;
						font-weight: 600;
						color: var(--white-transparent);
						background-color: var(--c1);
						transition: all 0.25s ease-in-out;
					}
				}

				.checkbox-wrapper {
					label {
						padding: 0 !important;

						.icon {
							width: 1rem;
							height: 1rem;
							position: relative;
							transform: none;
							top: unset;
							left: unset;

							svg {
								font-size: 1.5rem;
							}
						}

						span {
							display: none;
						}
					}

					input[type="checkbox"]:checked + label {
						border-color: var(--list-main-color);
						color: var(--c3);
						background-color: var(--list-main-color);
					}
				}
			}

			&:not(.title) {
				padding: 1rem 0 1rem 1rem;
			}

			&.title {
				margin-bottom: 1.25rem;

				.icon {
					width: 3rem;
					height: 3rem;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 0.625rem;
					background-color: var(--list-main-color);

					i {
						font-size: 1.5rem;
					}
				}

				.wrapper {
					flex-basis: 0;
					flex-grow: 1;
					flex-shrink: 1;
					display: flex;
					flex-flow: wrap column;

					.title {
						width: 100%;
						text-align: left;

						&.p {
							font-size: 1.625rem;
							color: var(--list-main-color);
						}

						&.s {
							font-size: 1.25rem;
						}
					}
				}

				.btn-incluir {
					width: auto;
					border-radius: 1rem;
					border-color: var(--list-main-color);
					color: var(--c3-dark);
					background-color: var(--list-main-color);

					&:active {
						color: var(--c3-dark) !important;
						background-color: var(--list-main-color) !important;
					}
				}
			}
		}

		&.to-avoid {
			--list-main-color: var(--c17);
		}

		&.to-do {
			--list-main-color: var(--c12);
		}
	}
}

@media (max-width: 60rem) {
	.to-do-avoid-steps-content {
		.list {
			.item {
				flex-flow: wrap column;
				align-items: center;
				text-align: center;
				font-size: 1rem;

				.radios-container {
					width: 100%;
					align-items: center;

					.radio-wrapper {
						justify-content: center;
					}
				}

				&:not(.title) {
					gap: 1.5rem;
				}

				&:not(.title) {
					padding: 1rem 0 1rem 0;
				}
			}
		}
	}
}
