.Payments {
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	flex-flow: wrap row;
	align-items: center;
	justify-content: center;
	gap: 0;
	background-color: var(--c1-dark);
	overflow: auto;

	.plans {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		font-size: 1.25rem;

		.line {
			font-size: 1.875rem;
			font-weight: 600;
		}

		.title {
			display: flex;
			flex-flow: wrap column;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.a {
				font-size: 1.875rem;
				font-weight: 600;
			}

			.two {
				font-size: 1.25rem;
			}
		}
	}
}
