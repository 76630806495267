.ChartPerformanceAdmin {
	background: var(--c3);

	#chartdiv {
		width: 100%;
		height: 31.25rem;
	}

	#chartdiv {
		div[role="tooltip"] {
				display: none !important;
		}
	}
}
