.purpose {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 2.625rem;

	.title {
		font-family: 'Roboto' !important;
		font-style: normal !important;
	}
	
	.text {
		font-family: 'Roboto' !important;
		font-weight: 400 !important;
	}
}
