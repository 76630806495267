.CalendarTableComponent {
	table {
		thead {
			tr {
				th {
					padding: 0.5rem 1.5rem 0.5rem;
					background: var(--c2);
					color: var(--c1-dark);
					font-size: 1.6rem !important;
					text-transform: capitalize !important;
					font-weight: 600;

					&:first-child {
						border-radius: 0.9375rem 0 0 0;
					}

					&:last-child {
						border-radius: 0 0.9375rem 0 0;
					}
				}
			}
		}

		&.table th {
			border-top: none !important;
		}

		tbody {
			tr {
				td {
					padding: 1.5rem 1.5rem 1.5rem;
					background: var(--c3);
					color: var(--text);
					vertical-align: middle !important;
					font-size: 1.4rem;
					font-weight: 600;
				}

				&:last-child {
					td {
						&:first-child {
							border-radius: 0 0 0 0.9375rem;
						}

						&:last-child {
							border-radius: 0 0 0.9375rem 0;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 64rem) {
	.CalendarTableComponent {
		table {
			thead {
				tr {
					th {
						font-size: 1.3rem !important;
					}
				}
			}

			tbody {
				tr {
					td {
						font-size: 1rem;
					}
				}
			}
		}
	}
}
