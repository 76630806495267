.resources-info {
	margin-top: 2rem;
	position: relative;
  
	img {
	  position: absolute;
	  margin-left: 1rem;
	  margin-top: -2rem;
	  width: 4.625rem;
	  z-index: 1; /* Adicionado para posicionar a imagem acima do elemento .card-resource */
	}
  }
  
  .card-resource {
	border-radius: 8px;
	width: 23rem;
	position: relative;
  
	.title {
	  font-size: 1.5rem;
	  text-align: center;
	}
  
	.body {
	  font-family: 'Roboto';
	  font-style: normal;
	  font-weight: 400;
	  height: 7rem;
	  overflow-y: scroll;
	}
  
	::-webkit-scrollbar-track {
	  background: transparent;        /* color of the tracking area */
	}
  
	padding-bottom: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
  }