.wrapper {
	width: 100%;
	position: relative;
	overflow-x: auto;
	display: block;

	.journey-backpack-item-row {
		width: max-content;
		min-width: 100%;
		word-break: keep-all;

		.journey-backpack-item-col {
			width: fit-content;
			min-width: 3.75rem;

			.btn {
				display: inline-flex;
				flex-flow: nowrap row;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
