//
// Pricing card
//

.card-pricing {
	.card-header {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}
	.list-unstyled li {
		padding: 0.5rem 0;
		color: $gray-600;
	}
}

.card-pricing.popular {
	z-index: 1;
	border: 3px solid theme-color("primary") !important;
}

@include media-breakpoint-up(md) {
	.card-pricing.zoom-in {
		z-index: 1;
		transform: scale(1.1);
	}
}
