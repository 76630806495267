.ChartEvolutionComfortZones {
	.swiper {
		width: 100%;
		height: 100%;

		.swiper-button {
			&-prev,
			&-next {
				margin: 0 !important;
				position: absolute;
				transform: translate(-50%, -50%);
				top: 50%;
				right: unset;
				color: var(--text);
				z-index: 2;
			}

			&-prev {
				left: 1rem;
			}

			&-next {
				left: calc(100% - 1rem);
			}
		}
	}

	.chart-wrapper {
		width: 100%;
		min-height: 35rem;
		max-width: 100%;
	}
}
