.input-file-row {
	width: 100%;
	display: flex;
	// flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;

	label {
		margin: 0;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.btn-image {
		background-color: #AAAE85 !important;
		color: var(--white) !important;
		border-radius: 6px;
		font-weight: 700;
		font-size: 18px;
		padding: 0;
		padding: 3px;
		padding-right: 25px;
		padding-left: 25px;
		&:hover,
		&:active,
		&:visited {
			// background-color: #76ab1c !important;
			// border-color: #76ab1c !important;
			color: var(--white) !important;
		}
	}

	input {
		display: none;
	}
}

@media (max-width: 56.25rem) {
	.input-file-row {
		label {
			font-size: 1rem;
		}
	}
}
