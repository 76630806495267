.notes {
	width: 100%;
}

.notes-actions {
	.actions {
		margin: 0 !important;
		button {
			color: var(--c1-dark);
			background-color: var(--c2) !important;
		}
		button:hover {
			color: var(--c1-light);
			background-color: var(--c1) !important;
			border-color: var(--c2);
		}
	}
}
