.VideoModal {
	.modal-backdrop {
		&.show {
			opacity: 1 !important;
			background-color: rgba(0, 0, 0, 0.75);
			backdrop-filter: grayscale(100%) blur(0.3125rem);
		}
	}

	.modal-title,
	.title {
		width: 100%;
		margin: 0;
		line-height: 1.25;
		text-align: left;
	}

	.title {
		font-size: 1.1rem;
		color: var(--text) !important;

		&.p {
			font-size: 1.2rem;
		}

		&.t {
			font-size: 1rem;
		}
	}

	.modal-content {
		background-color: var(--c1) !important;
	}

	.modal-header {
		padding: 1.5rem !important;
		align-items: stretch;
		justify-content: flex-start;
		gap: 1rem;
		color: var(--c1) !important;

		.modal-title {
			width: unset;
			flex-basis: 0;
			flex-grow: 1;
			flex-shrink: 1;
			font-size: 1.3rem !important;
			color: var(--c1) !important;
		}

		.close > span:not(.sr-only) {
			height: 100%;
			margin: 0 !important;
			padding: 0 !important;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--c1) !important;

			* {
				pointer-events: none;
			}
		}
	}

	.modal-body {
		width: 100%;
		padding: 1.5rem 2.5rem !important;
		display: flex;
		flex-flow: wrap column;
		gap: 1rem;
		color: var(--white);

		.video-container {
			width: 100%;
			height: 100%;
		}
	}
}
