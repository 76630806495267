.range-row {
	width: 100%;
	display: flex;
	align-items: stretch;
	justify-content: center;
	gap: 2rem;

	label,
	.input-wrapper {
		display: block;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}

	label {
		font-size: 1.125rem;
		font-weight: 400;
	}

	.input-wrapper {
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;

		.input {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;

			input {
				--range-label-width: 3.75rem;
				--range-track-height: 0.5rem;
				--range-handle-size: 1.25rem;

				// width: calc(100% - (var(--range-label-width) + 0.625rem));
				height: var(--range-track-height);
				margin: 0;
				padding: 0;
				display: block;
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				-webkit-appearance: none;
				border-radius: 0.3125rem;
				outline: none;
				cursor: pointer;
				background: var(--range-track-color);

				&::-webkit-slider-thumb {
					width: var(--range-handle-size);
					height: var(--range-handle-size);
					-webkit-appearance: none;
					border-radius: 50%;
					cursor: pointer;
					background: var(--range-handle-color);
					transition: all 0.16s ease-in-out;

					&:hover {
						transform: scale(1.2);
						background: var(--range-handle-color-hover);
					}
				}
				&::-moz-range-thumb {
					width: var(--range-handle-size);
					height: var(--range-handle-size);
					border-radius: 50%;
					border: 0;
					cursor: pointer;
					background: var(--range-handle-color);
					transition: background 0.15s ease-in-out;

					&:hover {
						background: var(--range-handle-color-hover);
					}
				}
			}
		}

		output {
			width: 100%;
			display: inline-block;
			text-align: center;
			font-size: 1rem;
			font-weight: 500;
		}
	}
}

@media (max-width: 56.25rem) {
	.range-row {
		flex-flow: wrap column;

		label {
			font-size: 1rem;
		}

		.input-wrapper {
			min-width: 80%;
		}
	}
}
