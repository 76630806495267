.ModalGradesSub {
	.modal-header {
		padding: 1.5rem 2.5rem !important;
		align-items: center;
	}

	.subSelected {
		border-width: 6px;
		border-style: solid;
	}

	.subNotSelected {
		border: 0 0 0;
	}

	.wrapper {
		width: 100%;
		position: relative;
		display: flex;
		flex-flow: column;
		border-radius: 0.75rem;
		color: var(--white);
		font-size: 1.625rem;
		justify-content: center;
		overflow: hidden;

		.card-title {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 0.9375rem;
			margin: 0 0 0.625rem;

			.grouping {
				display: flex;
				flex-flow: column;
				position: relative;
				font-weight: bold;

				.main-text {
					font-size: 2rem;
					line-height: 2rem;
				}

				.sub-text {
					font-size: 1.6rem;
					line-height: 1.8rem;
					font-weight: 500;
					color: var(--text);
				}
			}
		}

		.group {
			align-items: center;
			justify-content: space-between;
			display: grid;
			flex-flow: column;
			gap: 1.2rem;
			width: 100%;
			grid-template-columns: 1fr 1fr;

			.card {
				width: 100%;
				background: var(--c3);
				text-align: center;
				padding: 0.9375rem 0.1875rem;
				box-shadow: none;
				margin: 0;
				min-height: 20rem;

				p {
					font-size: 1.4rem;
					font-weight: 600;
					margin-top: 0.625rem;
					color: var(--text);
				}
			}

			.graphic {
				position: relative;
				padding: 0 0.625rem;
				margin-bottom: 1.25rem;

				p {
					position: absolute;
					top: calc(50% - 2.5rem);
					left: 0;
					text-align: center;
					font-size: 3.4rem;
					font-weight: 600;
					width: 100%;
					animation: fadeIn 2s;
				}
			}
		}
	}
}

@media (max-width: 60rem) {
	.ModalGradesSub {
		.wrapper {
			.group {
				grid-template-columns: 1fr;

				.graphic {
					width: 80%;
					margin: 0 auto 1.25rem auto;
				}
			}
		}
	}
}
