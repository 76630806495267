.Footer {
	font-weight: bold;
	width: 100%;
	text-align: center;
	padding: 1.25rem;
	font-size: 0.6875rem;
	color: rgba(128, 127, 107, 1);
}

@media (max-width: 47.9375rem) {
	.Footer {
		@media (max-width: 64rem) {
			text-align: center;
			font-size: 0.6875rem;
		}
	}
}
