//
// Forms
//

// Labels

.form-control-label {
	color: $gray-700;
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;
}

// Text inputs

.form-control {
	font-size: $input-btn-font-size;
	@include transition($transition-ease-in-out);
	height: calc(
		#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border *
			2.5}
	);

	&:focus {
		&::placeholder {
			color: $input-focus-placeholder-color;
		}
	}
}

.form-control-lg {
	height: calc(
		#{$input-line-height * 1.5em} + #{$input-padding-y * 2} + #{$input-height-border *
			2.5}
	);
}

.form-control-sm {
	height: calc(
		#{$input-line-height * 0.3em} + #{$input-padding-y * 2} + #{$input-height-border *
			2.5}
	);
}

// Form control flush
// Removes borders and paddings from inputs and text areas

.form-control-flush {
	padding: 0;
	border-width: 0;
	background-color: transparent;
	box-shadow: none;

	&:focus {
		background-color: transparent;
		box-shadow: none;
		border-width: 0;
	}
}

// Textarea

textarea[resize="none"] {
	resize: none !important;
}

textarea[resize="both"] {
	resize: both !important;
}

textarea[resize="vertical"] {
	resize: vertical !important;
}

textarea[resize="horizontal"] {
	resize: horizontal !important;
}

// Form input variations

// Muted input

.form-control-muted {
	background-color: $input-muted-bg;
	border-color: $input-muted-bg;
	box-shadow: none;

	&:focus {
		background-color: $input-focus-muted-bg;
	}
}

// Alternative input

.form-control-alternative {
	box-shadow: $input-alternative-box-shadow;
	border: 0;
	transition: box-shadow 0.15s ease;

	&:focus {
		box-shadow: $input-focus-alternative-box-shadow;
	}
}

// Size variations: Fixes to the bootstrap defaults

.form-control-lg,
.input-group-lg .form-control,
.input-group-text {
	font-size: $font-size-base !important;
}
