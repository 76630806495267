@charset "UTF-8";
@use "sass:math";

[color-scheme="light"] {
	.macro-goals-item {
		.card {
			--header-bg: #8b8a79;
		}
	}
}

.macro-goals-item {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;

	.form-check {
		width: 100%;
		max-width: 100%;
		margin: 0 !important;
		padding: 1rem 0 !important;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: nowrap row;
		gap: 0;
	}

	.input-wrapper {
		width: 4rem;
		max-width: 0;
		height: 100%;
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}

	label {
		width: 100%;
		color: var(--white) !important;
	}

	.card {
		--bg: var(--c3);
		--header-bg: #161515;

		width: auto;
		margin: 0;
		position: relative;
		transform: translateY(0);
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0;
		border: none;
		background-color: var(--bg);
		box-shadow: none;
	}

	.macro-goals-item-row {
		width: 100%;
		padding: 1rem 2rem;
		display: flex;
		flex-flow: wrap row;
		align-items: center;
		justify-content: center;
		gap: 2rem;

		&.title-row {
			border-radius: 0.375rem 0.375rem 0 0;
			background-color: var(--header-bg);
		}

		&.content-row {
			border-radius: 0 0 0.375rem 0.375rem;
			color: var(--text);
			background-color: transparent;
		}

		&.is-gapless {
			gap: 0;
		}
	}

	.macro-goals-item-col {
		height: 100%;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;

		.data-icons {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.col-text-question {
			width: 85%;
			float: left;
		}

		.col-icons {
			width: 15%;
			float: left;
			display: grid;
			align-items: end;
			justify-content: end;
			padding: 0.625rem;
			color: var(--text);
		}

		.content {
			font-weight: 600;
			font-size: 1.125rem;
		}

		input {
			width: 100%;
			height: 2.625rem;
			color: var(--text);
			background-color: var(--c3);
			font-size: 1rem;

			&:disabled {
				opacity: 0.6;
			}
		}

		@for $i from 0 through 12 {
			&.is-#{$i} {
				flex: none;
				width: percentage(math.div($i, 12));
			}
		}

		button {
			width: 100%;
			margin: 0 0 0 auto;
			border: none;
			border-radius: 0.375rem;
			color: var(--text);
			background-color: var(--bg);
			box-shadow: none !important;

			&:hover {
				border-color: var(--c2);
				background-color: var(--c2);
			}
		}
	}

	.title {
		width: 100%;
		line-height: 1.5;
		text-align: left;
		font-size: 1.25rem;
		font-weight: 600;
	}

	.mb-label {
		width: 100%;
		display: none;
		text-align: left;
		font-size: 1.15rem;
		font-weight: 700;
	}

	&.enable-selection {
		.input-wrapper {
			max-width: 100%;
		}
	}

	&.uelf {
		.card {
			.title-row {
				.title {
					color: var(--white);
				}
			}

			.content-row {
				color: var(--c1);
				background-color: var(--c2);
			}
		}
	}
}

@media screen and (max-width: 70rem) {
	.macro-goals-item {
		.macro-goals-item-row {
			padding: 2rem;
			flex-flow: wrap column;

			&.title-row {
				display: none;
			}

			&.content-row {
				border-radius: 0.375rem;
			}
		}

		.macro-goals-item-col {
			width: 100%;
			gap: 0.5rem;

			@for $i from 0 through 12 {
				&.is-#{$i} {
					width: 100%;
				}
			}
		}

		.card {
			.mb-label {
				display: block;
			}
		}
	}
}
