.CardSurveyAssessment {
	width: 100%;
	height: auto;
	padding: 2.5rem 3.75rem;
	border-radius: 0.625rem;
	color: var(--c1-dark);
	background-color: var(--c3);

	.content {
		width: 100%;
		height: auto;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		gap: 0;
		border-radius: 0.625rem;
		background-color: var(--c2);

		.square {
			width: 86%;
			display: flex;
			flex-flow: wrap column;
			padding: 5.5rem 6.125rem;
			gap: 2.2rem;
			box-sizing: border-box;

			.button {
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;

				.btn-result {
					min-width: 30%;
					padding: 0.75rem;
					border: none;
					border-radius: 0.25rem;
					color: var(--c1-dark);
					background-color: var(--c1-light);
				}
			}
		}

		.imageTwo {
			width: 50%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
}

@media (max-width: 64rem) {
	.CardSurveyAssessment {
		padding: 1rem;

		.content {
			display: flex;
			flex-flow: column-reverse;
			gap: 0;

			.imageTwo {
				width: 100%;
				height: 16rem;
				object-fit: cover;
				border-radius: 0.625rem;
				display: none;
			}

			.square {
				width: 100%;
				padding: 1rem;
				gap: 1.2rem;

				.image {
					text-align: center;
				}

				.button {
					.btn-result {
						width: 100%;
						height: auto;
						padding: 0.375rem;
					}
				}
			}
		}
	}
}
