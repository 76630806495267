.note-of-life {
	height: 100%;
	margin: 0;
	padding: 1.625rem 3.125rem;
	flex-flow: wrap column;
	align-items: center;
	justify-content: center;
	gap: 4rem;
	border-radius: 0.75rem;
	background-color: var(--c3);

	.chart-wrapper {
		.chart-label {
			width: 100%;
			display: flex;
			flex-flow: wrap column;
			gap: 0.25em;
			text-align: center;

			.title,
			.subtitle,
			.status {
				width: 100%;
				line-height: 1.5;
				text-align: center;
				font-weight: 700;
				color: var(--c2);
				word-break: normal;
			}

			.title {
				font-size: 2rem;
			}

			.subtitle {
				font-size: 1.4rem;
			}

			.status {
				font-size: 1.7rem;
			}
		}
	}

	.items-wrapper {
		height: 100%;
		justify-content: center;
		padding: 0;

		.help-text {
			width: 100%;
			text-align: center;
			font-size: 0.875rem;
			font-weight: 400;
			color: var(--white);
		}
	}
}

@media (max-width: 64rem) {
	.note-of-life {
		flex-direction: column;
	}
}
