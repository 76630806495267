.themeToggle {
	width: 1.875rem;
	height: 1.875rem;
	margin: 0 !important;
	padding: 0 !important;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	appearance: none;
	cursor: pointer;
	user-select: none;
	outline-style: none;
	outline-offset: 0;
	border-radius: 50%;
	border-width: 0.0625rem;
	border-style: solid;
	border-color: var(--c3);
	line-height: 1;
	text-decoration: none;
	font-family: inherit;
	font-style: normal;
	font-size: 0.875rem;
	font-weight: 700;
	color: var(--c2);
	background-color: var(--c3);
	box-shadow: none;
	transition-property: outline-offset, background-color;
	transition-duration: 0.2s;
	transition-timing-function: ease;

	.iconContainer {
		width: 1rem;
		height: 1rem;
		position: relative;
		overflow: hidden;

		svg {
			opacity: 0;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			transition-property: opacity, top;
			transition-duration: 0.1s, 0.2s;
			transition-timing-function: ease-in-out;

			&.light {
				top: -150%;
			}

			&.dark {
				top: 150%;
			}
		}
	}

	&.light {
		.iconContainer {
			svg.light {
				opacity: 1;
				top: 50%;
			}
		}
	}

	&.dark {
		.iconContainer {
			svg.dark {
				opacity: 1;
				top: 50%;
			}
		}
	}
}
