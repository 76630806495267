[color-scheme="light"] {
	.goals-wrapper {
		.content-container {
			.priority-wrapper {
				.priority-item {
					label {
						// --background: #ecece9;
						color: var(--text);

						.text {
							& > p {
								color: var(--text);
							}
						}
					}
				}
			}
		}
	}
}

.goals-wrapper {
	width: fit-content;
	max-width: 100%;
	min-width: 100%;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	gap: 1.5rem;

	.stages-container,
	.content-container {
		padding: 2rem 1.75rem;
		border-radius: 0.75rem;
		background-color: var(--c1);
	}

	input,
	select {
		border-radius: 1rem;
		border-color: var(--c1);
		color: var(--text) !important;
		background-color: var(--c1);
		box-shadow: 0.0625rem 0.0625rem 0.0625rem var(--c1);
	}

	input:focus,
	select:focus {
		background-color: #3b3a32;
		border-color: white;
	}

	.stages-container {
		position: sticky;
		min-width: 15rem;
		top: 1.75rem;
		display: flex;
		flex-flow: wrap column;
		gap: 2rem;
		height: auto;

		.progress-wrapper {
			width: 100%;
			display: flex;
			text-align: left;
			font-size: 1.125rem;
			font-weight: 500;
			font-style: normal;
			color: var(--text);
		}

		.progress-wrapper {
			padding: 0;
			flex-flow: wrap column;
			gap: 1rem;

			label {
				width: 100%;
				margin: 0;
			}

			.progress {
				height: 0.5rem;
				margin: 0;
				display: flex;
				border-radius: 0.625rem;
				background-color: var(--c3);

				&-bar {
					height: 100%;
					border-radius: 0.625rem;
					background-color: var(--c1);
				}
			}
		}

		.stage-indicator {
			width: 100%;
			padding: 1rem;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 1rem;
			user-select: none;
			border-radius: 0.25rem;
			font-size: 1.125rem;
			font-weight: 400;
			font-style: normal;
			background-color: var(--c5);
			transition-property: opacity, background-color;
			transition-duration: 0.25s, 0.15s;
			transition-timing-function: ease-in, ease-in;

			.icon-wrapper {
				width: 2.5rem !important;
				height: 2.5rem !important;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0.5rem;
				font-size: 1rem;
				color: var(--text);
				background-color: var(--c2);
				transition-property: color, background-color;
				transition-duration: 0.15s;
				transition-timing-function: ease-in;
			}

			span {
				display: block;
				color: var(--white);
			}

			&::after {
				content: "";
				width: 0.3125rem;
				height: 70%;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				right: 0;
				border-radius: 0.5rem;
			}

			&:focus,
			&.is-active {
				.icon-wrapper,
				&::after {
					background-color: var(--c2);
				}
			}
		}
	}

	.priority-button-wrapper {
		width: 100%;
		display: flex;
		flex-flow: wrap row;
		align-items: flex-end;
		justify-content: flex-end;
	}

	.content-container {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;

		.title {
			color: var(--text);
		}

		.text {
			color: var(--text);
		}

		.priority-wrapper {
			width: 100%;
			display: flex;
			flex-flow: wrap row;
			align-items: stretch;
			justify-content: flex-start;
			gap: 2rem;

			.priority-item {
				width: fit-content;
				min-width: 15rem;
				max-width: 15rem;
				position: relative;
				display: flex;

				.title-wrapper {
					width: 100%;
					display: flex;
					flex-flow: wrap column;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 0.5rem;

					* {
						margin: 0 !important;
					}

					.sub-resource {
						font-size: 0.75rem;
					}

					.resource {
						font-size: 1rem;
					}
				}

				.row-align {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.image-icon {
						float: right;
						align-items: center;
						justify-content: center;
						display: flex;
					}
				}

				input[type="radio"] {
					width: 0;
					height: 0;
					margin: 0;
					padding: 0;
					visibility: hidden;
					position: absolute;

					&:checked ~ label {
						span.index {
							color: var(--c2);
							background-color: var(--background);
						}
					}
				}

				label {
					--background: #3b3a32;

					min-width: 15rem;
					margin: 0;
					padding: 1rem;
					position: relative;
					overflow: hidden;
					display: flex;
					flex-flow: wrap;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 1rem;
					user-select: none;
					cursor: pointer;
					border-radius: 0.625rem;
					background-color: var(--background);
					transition: all 0.25s ease-in;

					span.index {
						width: 1.375rem;
						height: 1.375rem;
						margin: 0;
						padding: 0.675rem;
						overflow: hidden;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						font-size: 1rem;
						color: var(--c1);
						background-color: var(--white);
						z-index: 2;
					}

					.text-wrapper::-webkit-scrollbar-track {
						background-color: transparent;
					}

					.text-wrapper {
						width: 100%;
						max-height: 9.0625rem;
						overflow-y: scroll;
						


						.text {
							width: 100%;
							display: flex;
							font-size: 1.125rem;

							& > p {
								line-height: 1.5;
								color: var(--white);
							}

							&:hover {
								animation-name: scroll;
								animation-duration: 60s;
							}
						}
					}
				}
			}
		}

		.goals-wrapper {
			flex-flow: wrap column;
		}

		label {
			font-size: 1rem;
		}

		button.stage-forward {
			margin: 0 0 0 auto;
			color: var(--c1);
			border-color: var(--c2);
			background-color: var(--c2);

			&:active,
			&:focus,
			&:hover {
				border-color: var(--c2);
				background-color: var(--c2);
				box-shadow: none;
			}
		}

		button.btn-view {
			color: #adcd56 !important;
			background-color: var(--c1) !important;
			border: 0.0625rem solid;
		}
	}
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

@media (max-width: 60rem) {
	.goals-wrapper {
		flex-flow: wrap column;

		.stages-container {
			position: relative;
			top: unset;
		}

		.content-container {
			.priority-wrapper {
				.priority-item {
					width: 100%;
					max-width: 100%;
					
					label {
						width: 100%;
						min-width: 100%;
						padding: 4rem 1rem 1rem 1rem;
						font-size: 0.95rem;
						font-weight: 600;
					}
				}
			}
		}
	}
}

@media (max-width: 36rem) {
	.goals-wrapper {
		.content-container {
			.priority-wrapper {
				justify-content: center;
			}
		}
	}
}
