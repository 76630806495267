.Timeline {
	&::before {
		border: none !important;
		left: 0 !important;
		margin-left: 0 !important;
	}

	.card {
		background-color: var(--c3) !important;
		border: none;
		box-shadow: 0 0 2rem 0 rgb(59 58 50) !important;
		--shadow-color: 54deg 13% 10%;
		--shadow-elevation-low: 0.0187rem 0.0313rem 0.0437rem
				hsl(var(--shadow-color) / 0.34),
			0.025rem 0.05rem 0.0625rem -0.075rem hsl(var(--shadow-color) / 0.34),
			0.0625rem 0.125rem 0.1563rem -0.1563rem hsl(var(--shadow-color) / 0.34);
		--shadow-elevation-medium: 0.0187rem 0.0313rem 0.0437rem
				hsl(var(--shadow-color) / 0.36),
			0.05rem 0.1rem 0.125rem -0.05rem hsl(var(--shadow-color) / 0.36),
			0.1313rem 0.2562rem 0.325rem -0.1062rem hsl(var(--shadow-color) / 0.36),
			0.3125rem 0.625rem 0.7875rem -0.1563rem hsl(var(--shadow-color) / 0.36);
	}

	.stars {
		width: fit-content;
		min-width: 100%;
		display: flex;

		& > div {
			& > div {
				display: flex;
				flex-flow: nowrap row;
			}
		}
	}

	table.table-cronograma {
		thead {
			tr {
				th {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
					background: var(--c16);
					color: var(--c1-light);
					font-size: 1.6rem !important;
					text-transform: capitalize !important;
				}
			}
		}

		&.table th {
			border-top: none !important;
		}

		tbody {
			tr {
				td {
					color: var(--text);
					vertical-align: middle !important;
					font-size: 1.3rem;
					font-weight: 500;
				}
			}
		}

		.circle-index {
			width: 3.125rem;
			height: 3.125rem;
			margin: 0 !important;
			padding: 0 !important;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.6rem;
			font-weight: 900;
			border-radius: 50%;
			color: var(--c1-dark);
			background: var(--c2);
		}
	}

	.border-top-left-radius {
		border-top-left-radius: 0.3125rem;
	}

	.border-top-right-radius {
		border-top-right-radius: 0.3125rem;
	}

	.actions {
		width: 100%;
		display: flex;
		flex-flow: nowrap row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;

		button {
			margin: 0;
			color: var(--c1-dark) !important;
			border-color: var(--c2);
			background-color: var(--c2);
			font-size: 1.25rem;

			&:focus,
			&:focus-within,
			&:hover,
			&:active,
			&.active {
				border-color: var(--c2) !important;
				background-color: var(--c2) !important;
			}

			&.floating-button {
				position: fixed;
				bottom: 2rem;
				right: 2rem;
				z-index: 1;
			}
		}
	}

	.timeline-actions {
		.actions {
			button {
				opacity: 0.5;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	.nav-tabs {
		border-bottom: none;
	}

	.react-calendar {
		width: 25rem;
		max-width: 100%;
		background-color: #fff;
		color: #222;
		border-radius: 0.5rem;
		box-shadow: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.2);
		font-family: Arial, Helvetica, sans-serif;
		line-height: 1.125em;
	}
	.react-calendar__navigation button {
		color: #6f48eb;
		min-width: 2.75rem;
		background: none;
		font-size: 1rem;
		margin-top: 0.5rem;
	}
	.react-calendar__navigation button:enabled:hover,
	.react-calendar__navigation button:enabled:focus {
		background-color: #f8f8fa;
	}
	.react-calendar__navigation button[disabled] {
		background-color: #f0f0f0;
	}
	abbr[title] {
		text-decoration: none;
	}
	/* .react-calendar__month-view__days__day--weekend {
		color: #d10000;
	   } */
	.react-calendar__tile:enabled:hover,
	.react-calendar__tile:enabled:focus {
		background: #f8f8fa;
		color: #6f48eb;
		border-radius: 0.375rem;
	}
	.react-calendar__tile--now {
		background: #6f48eb33;
		border-radius: 0.375rem;
		font-weight: bold;
		color: #6f48eb;
	}
	.react-calendar__tile--now:enabled:hover,
	.react-calendar__tile--now:enabled:focus {
		background: #6f48eb33;
		border-radius: 0.375rem;
		font-weight: bold;
		color: #6f48eb;
	}
	.react-calendar__tile--hasActive:enabled:hover,
	.react-calendar__tile--hasActive:enabled:focus {
		background: #f8f8fa;
	}
	.react-calendar__tile--active {
		background: #6f48eb;
		border-radius: 0.375rem;
		font-weight: bold;
		color: white;
	}
	.react-calendar__tile--active:enabled:hover,
	.react-calendar__tile--active:enabled:focus {
		background: #6f48eb;
		color: white;
	}
	.react-calendar--selectRange .react-calendar__tile--hover {
		background-color: #f8f8fa;
	}
	.react-calendar__tile--range {
		background: #f8f8fa;
		color: #6f48eb;
		border-radius: 0;
	}
	.react-calendar__tile--rangeStart {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 0.375rem;
		border-bottom-left-radius: 0.375rem;
		background: #6f48eb;
		color: white;
	}
	.react-calendar__tile--rangeEnd {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 0.375rem;
		border-bottom-right-radius: 0.375rem;
		background: #6f48eb;
		color: white;
	}
}

@media (max-width: 64rem) {
	.Timeline {
		.container {
			padding: 0;
		}

		.col-md-6 {
			&.pl-0 {
				padding-right: 0 !important;
			}

			&.pr-0 {
				margin-top: 1.25rem;
				padding-left: 0 !important;
			}
		}
	}
}
