.DashboardTrackScore {
	flex-flow: nowrap row !important;

	.list-wrapper {
		width: 80%;
		display: flex;
		flex-flow: wrap column;
		gap: 1rem;

		.title-wrapper {
			min-height: 6.25rem;
			margin: 0 0 2rem 0 !important;
		}

		.wrapper {
			width: 100%;
			display: flex;
			flex-flow: nowrap row;
			gap: 1rem;

			.item {
				&:first-child {
					width: 80%;
				}

				&:last-child {
					width: 20%;
				}
			}
		}
	}

	.note-wrapper {
		width: 20%;
		position: relative;

		.note {
			position: sticky;
			top: 1rem;
			display: flex;
			flex-flow: wrap column;
			align-items: center;
			justify-content: flex-start;

			.title,
			.subtitle {
				width: fit-content;
				text-transform: uppercase;
				font-family: "Futura PT", sans-serif !important;
				font-weight: 700;
			}

			.title {
				font-size: 3rem;
			}
		}
	}
}

@media (max-width: 56.25rem) {
	.DashboardTrackScore {
		.list-wrapper {
			.wrapper {
				flex-flow: wrap column;
			}
		}
	}
}
