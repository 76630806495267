[color-scheme="dark"] {
	.ProgressTrackerSubItem {
		.charts {
			.items-wrapper {
				h1 {
					color: var(--white);
				}
			}
		}

		.message {
			color: var(--white);
		}
	}
}

.ProgressTrackerSubItem {
	width: 100%;

	.border-bottom {
		border-bottom: 0.0625rem solid var(--text);
		border-radius: 0 !important;
	}

	.charts {
		.items-wrapper {
			display: flex;
			gap: 3.125rem;
			justify-content: center;
			flex-direction: row;
			width: 100%;

			h1 {
				font-size: 4.6875rem;
				color: var(--c3-dark);
				width: 100%;
				margin: 0;
			}

			.chart {
				width: 40%;
			}
		}
	}

	.message {
		background-color: var(--c3);
		color: var(--c3-dark);
	}
}
