.to-do-avoid-wrapper {
	// width: fit-content;
	max-width: 100%;
	min-width: 100%;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	gap: 1.5rem;

	.stages-container {
		min-width: 15rem;
		height: fit-content;
		padding: 2rem 1.75rem;
		position: sticky;
		top: 1.75rem;
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
		border-radius: 0.75rem;
		background-color: var(--c3);

		.stage-indicator {
			opacity: 0.5;
			width: 100%;
			padding: 1rem;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			user-select: none;
			border-radius: 0.25rem;
			font-size: 1.125rem;
			font-weight: 400;
			font-style: normal;
			color: var(--white);
			background-color: var(--c5);
			transition-property: opacity, background-color;
			transition-duration: 0.25s, 0.15s;
			transition-timing-function: ease-in, ease-in;

			&.color1 {
				background-color: var(--c12);
			}

			&.color2 {
				background-color: var(--c13);
			}

			span {
				display: block;
				color: var(--white);
			}

			&.is-active {
				opacity: 1;
			}
		}
	}

	.content-container {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-flow: wrap column;
		gap: 1.5rem;
	}
}

@media (max-width: 60rem) {
	.to-do-avoid-wrapper {
		flex-flow: wrap column;

		.stages-container {
			position: relative;
			top: unset;
		}
	}
}
