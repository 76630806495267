@charset "UTF-8";
@use "sass:math";

.success-roadmap-item {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-flow: wrap column;
	gap: 0;
	color: var(--text);

	.item-row {
		width: 100%;
		display: flex;
		flex-flow: wrap row;
		gap: 0;

		.title-row,
		.content-row {
			height: 100%;
			padding: 1rem 2rem;
			align-items: stretch;
			gap: 1rem;
		}

		&.title-row {
			display: none;
			background-color: var(--card-background);
		}

		&.roadmap-wrapper {
			opacity: 0;
			width: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;
			display: none;
			flex-flow: wrap column;
			position: relative;
			pointer-events: none;
			background-color: transparent;

			.card {
				& > .item-row {
					display: grid;
					grid-auto-flow: column;

					.item-col {
						overflow-x: auto;

						.overflow-x {
							position: relative;
							display: block;

							.title-row,
							.content-row {
								width: max-content;
								flex-flow: nowrap row;

								.item-col {
									width: 15rem;
									flex: none;
								}
							}
						}
					}
				}

				&::before {
					content: "";
					width: 0.625rem;
					height: 0.625rem;
					position: absolute;
					transform: translate(-50%, -50%);
					top: 50%;
					left: -2.5%;
					border-radius: 50%;
					background-color: var(--white);
				}
			}

			&::before {
				content: "";
				width: 0.25rem;
				height: calc(80% - 2rem);
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				left: 2.5%;
				border-radius: 0.375rem;
				background-color: var(--c2);
			}

			&.is-active {
				opacity: 1;
				padding: 2.5rem 0 2rem 5%;
				display: flex;
				pointer-events: auto;
				transition-property: opacity;
				transition-duration: 0.32s;
				transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
			}
		}

		&.is-gapless {
			gap: 0;
		}
	}

	.item-col {
		height: auto;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;

		@for $i from 0 through 12 {
			&.is-#{$i} {
				flex: none;
				width: percentage(math.div($i, 12));
			}
		}
	}

	.card {
		width: 100%;
		margin: 0 !important;
		padding: 0 !important;
		position: relative;
		display: block;
		border: none !important;
		border-radius: 0 !important;
		background-color: var(--card-background);
		box-shadow: none;

		input,
		.select,
		.date-input,
		.stars {
			width: 100%;
			height: 3.75rem;
			padding: 0.625rem 0.75rem !important;
			position: relative;
			border-radius: 0.5rem;
			border: 0.0625rem solid var(--white);
			font-size: 0.875rem;
			color: var(--white) !important;
			caret-color: var(--white) !important;
			background-color: var(--card-background) !important;
			transition: all 0.35s ease-in;
		}

		.select {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			& > div {
				width: 100%;
			}
		}

		.actions-wrapper {
			width: fit-content;
			padding: 0.5rem 1rem;
			display: flex;
			flex-flow: wrap column;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			background-color: var(--card-background);

			button {
				width: 100%;
				margin: 0 !important;
				padding: 0.5rem !important;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: none !important;

				* {
					margin: 0 !important;
				}

				.vh {
					width: 0;
					height: 0;
					display: none;
					visibility: hidden;
				}
			}
		}

		.title {
			width: 100%;
			line-height: 1.5;
			text-align: left;
			font-size: 1.375rem;
			font-weight: 600;
		}

		.stars {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.5rem;

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5em;
			}
		}

		.mb-label {
			width: 100%;
			display: none;
			text-align: left;
			font-size: 1.15rem;
			font-weight: 700;
		}

		.content {
			width: 100%;
			position: relative;
			overflow: hidden;
			display: block;
			font-size: 1.125rem;
			font-weight: 400;
		}
	}

	&.enable-selection {
		.input-wrapper {
			max-width: 100%;
		}
	}

	&.uelf {
		.card {
			.title-row {
				display: flex;

				.title {
					--color: var(--white);
					color: var(--color);
				}
			}

			.content-row {
				color: var(--c1);
				background-color: var(--c2);
			}
		}
	}

	&:nth-child(even) {
		.card {
			background-color: var(--card-background-even);
		}
	}

	&:nth-child(odd) {
		.card {
			background-color: var(--card-background-odd);
		}
	}
}

@media screen and (max-width: 70rem) {
	.success-roadmap-item {
		&:nth-child(odd) {
			.card {
				background-color: #44443c;
			}
		}

		.item-row {
			flex-flow: wrap column;

			&.roadmap-wrapper {
				.card {
					& > .item-row {
						.item-col {
							.overflow-x {
								.title-row,
								.content-row {
									.item-col {
										width: auto;
										padding: 1rem;
										justify-content: flex-start;
									}
								}
							}
						}
					}
				}
			}

			&.title-row {
				display: none !important;
			}
		}

		.item-col {
			width: 100%;

			@for $i from 0 through 12 {
				&.is-#{$i} {
					width: 100%;
				}
			}
		}

		.card {
			.mb-label {
				display: block;
				font-size: 1.765rem;
				font-weight: 700;
			}

			.content {
				font-size: 1.2rem;
				color: var(--c1);
			}

			.actions-wrapper {
				padding: 0.5rem;
				width: 100%;
			}
		}
	}
}
