.DashboardResourceScorePrint {
	align-items: stretch;
	padding: 5px;
	justify-content: flex-start;
	// gap: 2rem !important;
	// flex-flow: wrap column !important;
	flex-flow: nowrap row !important;

	.label {
		// min-width: 25rem;
		position: relative;
		// display: flex;
		// flex-flow: nowrap row;
		// align-items: center;
		gap: 1rem;

		.image {
			width: 3.75rem;
			height: 3.75rem;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.title-wrapper {
			.title {
				word-break: keep-all;

				&.p {
					font-size: 2.5rem;
					color: var(--c2);
				}

				&.s {
					font-size: 1.25rem;
				}
			}
		}

		.trophy {
			width: 8.75rem;
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.items-container {
		margin: 0 0 0 auto;
		padding: 0.5rem;
		display: block;
		overflow-x: auto;
		width: 100%;

		.wrapper {
			width: max-content;
			margin: 0 0 0 auto;
			display: flex;
			flex-flow: nowrap row;
			align-items: stretch;
			justify-content: flex-end;
			gap: 1rem;

			.item {
				width: 12rem;
				padding: 1rem;
				display: flex;
				flex-flow: wrap column;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;
				border-radius: 0.625rem;
				background-color: var(--c1);

				.title {
					text-align: center;
					font-size: 1rem;
				}

				.graph-wrapper {
					width: 100%;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					canvas {
						width: 10rem !important;
						height: 10rem !important;
						object-fit: contain;
					}

					.note {
						position: absolute;
						transform: translate(-50%, -50%);
						top: 50%;
						left: 50%;
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}

// @media (max-width: 80rem) {
// 	.DashboardResourceScorePrint {
// 		flex-flow: wrap column !important;

// 		.label,
// 		.items-container {
// 			width: 100%;
// 		}
// 	}
// }