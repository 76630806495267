.ConfigurationAdmin {
	padding: 1rem;
	--plyr-color-main: var(--c2);
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: center;
	justify-content: flex-start;
	gap: 2rem;
	color: var(--text);

	.row,
	.col {
		margin: 0 !important;
		padding: 0 !important;
	}

	.menu {
		margin-left: 2rem;
	}

	h3, h2 {
		color: var(--text) !important;
	}

	.btn-secondary {
		font-family: 'Roboto Flex' !important;
		line-height: 16px;
		font-size: 18px;
		border: 0;
		background: #AECD58 !important;
		color: var(--c1) !important;
		border-radius: 6px;
	}

	.card {
		width: 100%;
		position: relative;
		overflow: hidden;
		display: flex;
		border: none;
		border-radius: 0.75rem;
		background-color: var(--c3);
		color: var(--c1);
		box-shadow: none;

	}

}