.SuccessRoadmapStars {
	&.stars {
		width: 100%;
		height: 100%;
		padding: 0.625rem 0.75rem !important;
		position: relative;
		border-radius: 0.5rem;
		border: 0.0625rem solid var(--white);
		font-size: 0.875rem;
		color: var(--white) !important;
		caret-color: var(--white) !important;
		background-color: var(--c2) !important;
		transition: all 0.35s ease-in;

		div {
			div {
				display: flex;
				flex-flow: nowrap row;
				align-items: center;
				justify-content: space-evenly;
				gap: 1rem;
			}
		}
	}
}
