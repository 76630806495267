.ComfortZoneChart {
	display: flex;
	flex-flow: wrap column;
	gap: 2rem;

	.title-wrapper {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		gap: 0.5rem;

		span {
			display: block;
		}
	}

	.wrapper {
		width: 100%;
		display: flex;
		align-items: stretch;
		gap: 1rem;
	}

	.graph {
		width: 70%;
		display: block;
		margin: 0;
		padding: 0;
		overflow-x: auto;

		.graph-wrapper {
			width: max-content;
			min-height: 50rem;
			position: relative;

			.box {
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				display: flex;
				flex-flow: wrap column;
				align-items: flex-end;
				justify-content: center;
				border-radius: 50%;

				.content {
					width: fit-content;
					display: flex;
					flex-flow: wrap column;
					align-items: center;
					gap: 0.5rem;
					font-size: 1.125rem;

					span {
						width: max-content;
						display: block;
						word-break: keep-all;

						&.zone-title {
							font-weight: 700;
						}
					}
				}

				&.green {
					min-width: 70rem;
					min-height: 34.375rem;
					padding: 2rem 2rem 2rem 52rem;
					left: 0;
					background-color: #85ab44;
				}

				&.blue {
					min-width: 55rem;
					min-height: 28.125rem;
					padding: 2rem 2rem 2rem 39.5rem;
					left: 1rem;
					background-color: #56b6e3;
				}

				&.orange {
					min-width: 40rem;
					min-height: 21.875rem;
					padding: 2rem 2rem 2rem 27rem;
					left: 2rem;
					background-color: #d7a374;
				}

				&.purple {
					min-width: 27.5rem;
					min-height: 15.625rem;
					padding: 2rem 2rem 2rem 14.5rem;
					left: 3rem;
					background-color: #935aa5;
				}

				&.red {
					min-width: 12.5rem;
					min-height: 9.375rem;
					padding: 2rem;
					left: 4rem;
					align-items: center !important;
					background-color: #d1696f;
				}
			}

			.line {
				width: 50.25rem;
				height: 0.125rem;
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				left: 10.125rem;
				border-radius: 0.125rem;
				background-color: var(--white);

				.icon {
					width: 1.5rem;
					height: 1.5rem;
					position: absolute;
					transform: translate(-50%, -50%);
					top: 50%;
					left: 100%;
				}
			}
		}
	}

	.graph-label {
		width: 30%;
		padding: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;

		.text {
			padding: 1.625rem;
			border-radius: 0.5rem;
			font-size: 1.25rem;
			color: var(--text);
			background-color: var(--c1);
		}
	}
}

@media (max-width: 75rem) {
	.ComfortZoneChart {
		.wrapper {
			flex-flow: wrap column-reverse;

			.graph,
			.graph-label {
				width: 100%;
			}
		}
	}
}
