.CardEducationJourney {
	display: flex;
	flex-flow: column;
	gap: 1.5rem;

	.group {
		width: 100%;
		height: auto;
		position: relative;
		padding: 2rem 2.5rem;
		border-radius: 0.375rem;
		color: var(--text);
		background: var(--c3);

		.title {
			font-size: 1.875rem;
			font-weight: 700;
		}

		.content {
			display: flex;
			flex-flow: row;
			gap: 2rem;

			.text {
				font-size: 1.125rem;
			}
		}
	}

	.video {
		width: 100%;
		height: auto;
		position: relative;
		padding: 2rem 2.5rem;
		border-radius: 0.375rem;
		color: var(--text);
		background-color: var(--c3);

		video {
			width: 100%;
		}

		.title {
			font-size: 1.875rem;
			font-weight: 700;
			display: flex;
			bottom: 0.3125rem;
		}

		.video-container,
		.chart-container {
			width: 100%;
			display: flex;
			flex-flow: wrap column;
			align-items: center;
			justify-content: center;
			gap: 2rem;
		}

		.video-container {
			.player {
				width: 90%;
				position: relative;
				overflow: hidden;
				border-radius: 0.75rem;
			}
		}
	}

	p {
		a {
			margin: 0 0 0 0.5em;
			display: inline-flex;
			text-decoration: underline;
			font-size: inherit;
			color: var(--c2);
		}
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.125rem;
		gap: 1.125rem;

		.a {
			width: 8.125rem;
			height: 2.5rem;
			align-items: center;
			justify-content: center;
			border: 0.125rem solid var(--c2);
			border-radius: 0.25rem !important;
			text-align: center;
			color: var(--c2);
		}

		.two {
			width: 8.125rem;
			height: 2.5rem;
			border: none;
			border-radius: 0.25rem !important;
			color: var(--c3);
			background: var(--c2);
		}
	}

	button {
		&.btn-video-modal {
			margin: 1rem auto !important;
			border: none !important;
			color: var(--text) !important;
			background-color: var(--c1) !important;

			&:focus,
			&:hover {
				color: var(--text) !important;
				background-color: var(--c1) !important;
			}
		}
	}
}

@media (max-width: 64rem) {
	.CardEvolutionJourney {
		.group {
			height: auto;
			padding: 2rem 1.8rem;

			.content {
				display: block;
				text-align: justify;

				.graphic {
					display: flex;
					justify-content: center;
					margin: 0;
				}
			}
		}

		.video {
			padding: 2rem 1.8rem;
		}
	}
}
