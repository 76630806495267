.input-text-row {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;

	label {
		margin: 0;
		font-size: 1.125rem;
		font-weight: 400;
	}

	input,
	select,
	.input,
	.select {
		--border-color: var(--text) !important;
		--color: var(--text) !important;
		--background-color: var(--background-input);
		
		width: 100%;
		max-width: 60%;

		max-height: 3.75rem;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;
		border: 0 solid var(--border-color);
		font-size: 1rem;
		color: var(--color);
		caret-color: var(--color);
		background-color: var(--background-color);
		transition: all 0.35s ease-in;

		&:focus {
			border-color: var(--border-color);
			// background-color: var(--c3) !important;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			color: var(--color) !important;
			-webkit-text-fill-color: var(--color) !important;
			// background-color: var(--c1) !important;
			box-shadow: 0 0 0 1.875em var(--c1) inset !important;
		}

		&:disabled {
			opacity: 0.3;
		}

		&.invalid {
			border: 0.125rem solid var(--error);
		}
	}
}

@media (max-width: 56.25rem) {
	.input-text-row {
		label {
			font-size: 1rem;
		}

		input {
			width: 100%;
			max-width: 100%;
		}
	}
}
