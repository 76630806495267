.buttonNextScreen {
	margin-top: 2rem;

	.row,
	.col {
		margin: 0 !important;
		padding: 0 !important;
	}

	.btn-rastreador {
		border-color: var(--c2) !important;
		color: var(--c2) !important;
		background-color: var(--c1) !important;
	}
}
