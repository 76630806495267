.CardScore {
	&.wrapper {
		width: 100%;
		padding: 1.5625rem 1.875rem;
		margin-top: 0.9375rem;
		margin-bottom: 0.9375rem;
		position: relative;
		display: flex;
		flex-flow: column;
		border-radius: 0.75rem;
		color: var(--white);
		background: var(--c3);
		font-size: 1.625rem;
		justify-content: center;

		.card {
			display: flex;
			background: none;
			box-shadow: none;
			flex-direction: row;
			margin: 0;
			align-items: center;
			justify-content: flex-start;
			border: none;
			gap: 1rem;

			&.card-title {
				display: flex;
				align-items: center;
				flex-direction: row;
				gap: 1rem;
				margin: 0;

				.image {
					width: 4.5rem;
					height: 4.5rem;
					position: relative;
					overflow: hidden;
					border-radius: 50%;
					display: flex;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}

				.grouping {
					display: flex;
					flex-flow: column;
					position: relative;

					.title {
						font-size: 1.5rem;

						&.main {
							color: var(--text);
						}
					}
				}
			}

			.sentimento {
				width: 15.625rem;

				p {
					font-size: 1.5rem;
					font-weight: 600;
					color: var(--text);
				}
			}
		}

		.group {
			display: flex;
			gap: 1.875rem;
			align-items: center;

			.text {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				color: var(--text);

				* {
					margin: 0;
				}

				.options {
					ol.parentes {
						counter-reset: list;
					}
					ol.parentes > li {
						list-style: none;
						position: relative;
					}
					ol.parentes > li:before {
						counter-increment: list;
						content: counter(list, lower-alpha) ") ";
						position: absolute;
						left: -1.4em;
					}

					.avoid {
						display: flex;
						flex-direction: column;

						.titleAvoid {
							font-family: "Futura PT", sans-serif;
							font-size: 1.125rem;
							color: var(--c19);
						}
					}

					.does {
						display: flex;
						flex-direction: column;

						.titleDoes {
							font-family: "Futura PT", sans-serif;
							font-size: 1.125rem;
							color: var(--c2);
							font-weight: 700;
						}
					}
				}
			}

			.link {
				cursor: pointer;
				font-size: 1.2rem;
				font-weight: 600;
				color: var(--c10);
				text-decoration: underline;
			}

			.graphic {
				position: relative;

				p {
					position: absolute;
					top: calc(50% - 2.5rem);
					text-align: center;
					font-size: 3.4rem;
					font-weight: 600;
					width: 100%;
					color: var(--text);
					animation: fadeIn 2s;
				}
			}
		}
	}

	.money {
		color: var(--c12);
	}

	.health {
		color: var(--c10);
	}

	.emotions {
		color: var(--c8);
	}

	.time {
		color: var(--c9);
	}

	.knowledge {
		color: var(--c11);
	}
}

@media (max-width: 64rem) {
	.CardScore {
		&.wrapper {
			.card {
				.card-title {
					display: block;
				}

				.sentimento {
					width: 6.25rem;
				}
			}

			.group {
				display: block;
				margin-top: 0.625rem;

				.graphic {
					width: 86%;
					margin: 0 auto;
				}
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
