[color-scheme="light"] {
	.success-roadmap {
		--card-background: #8b8a79;
		--card-background-even: #fff;
		--card-background-odd: #8b8a79;

		.success-roadmap-item {
			p {
				color: var(--white);
			}

			&:nth-child(odd) {
				.card {
					color: var(--white);
				}
			}
		}
	}
}

.success-roadmap {
	--card-background: #161515;
	--card-background-even: #8b8a79;
	--card-background-odd: #55544a;

	width: 100%;
	position: relative;
	overflow: hidden;
	display: grid;
	grid-auto-flow: row;
	gap: 0;
	border-radius: 0.375rem;
	background-color: var(--card-background);

	.items {
		width: 100%;
	}

	.actions {
		width: 100%;
		display: flex;
		flex-flow: nowrap row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;
	}

	button {
		margin: 0 !important;
		color: var(--white);
		border-color: var(--white);
		background-color: transparent;

		&:focus,
		&:focus-within,
		&:hover,
		&:active {
			border-color: var(--c2);
			color: var(--c2);
			background-color: transparent;
		}

		&.floating-button {
			position: fixed;
			bottom: 2rem;
			right: 2rem;
			z-index: 1;
		}
	}
}

@media (max-width: 56.25rem) {
	.macro-goals {
		.actions {
			flex-flow: wrap column;
		}
	}
}
