.external-payment {
	width: 100%;
	min-height: 100vh;
	padding: 6.25rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	color: var(--white);
	background-color: var(--c1-dark);

	.image-wrapper,
	.content-wrapper {
		position: relative;
		display: flex;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}

	.image-wrapper {
		align-items: center;
		justify-content: center;

		img,
		svg {
			width: 80%;
			height: 80%;
			object-fit: contain;
			object-position: center;
		}
	}

	.content-wrapper {
		flex-flow: wrap column;
		align-items: flex-end;
		justify-content: center;
		gap: 2rem;

		.progress-indicator {
			position: relative;
			width: 31.25rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.step {
				display: flex;
				flex-flow: wrap column;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				z-index: 2;

				.number {
					width: 2.5rem;
					height: 2.5rem;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					border: 0.125rem solid var(--white);
					background-color: var(--c1-dark);
				}

				.label {
					font-size: 0.875rem;
				}

				&.is-active {
					.number {
						border-color: var(--c2-dark);
						color: var(--c2-dark);
					}

					.label {
						color: var(--c2-dark);
					}
				}
			}

			&::before {
				content: "";
				width: 90%;
				height: 0.25rem;
				position: absolute;
				transform: translate(-50%, -50%);
				top: 30%;
				left: 50%;
				border-radius: 0.25rem;
				background-color: var(--c0-dark);
				z-index: 1;
			}
		}

		.step-card {
			width: 31.25rem;
			max-width: 100%;
			padding: 2rem;
			position: relative;
			display: flex;
			flex-flow: wrap column;
			gap: 2rem;
			border-radius: 0.625rem;
			background-color: var(--c0-dark);

			.title,
			.label,
			.text {
				color: currentColor;
			}

			.title {
				font-family: "Roboto Flex", sans-serif !important;
				font-size: 1rem;
				font-weight: 700;
			}

			.label,
			.text {
				font-size: 0.875rem;
				font-weight: 400;
			}

			.text {
				width: 100%;
				display: flex;
				flex-flow: wrap column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 0.75rem;
				font-size: 0.875rem;

				* {
					margin: 0 !important;
					position: relative;
				}

				&.legal-notice {
					align-items: center;
					font-size: 0.75rem;
				}
			}

			.form {
				position: relative;
				gap: 1.5rem;

				.fieldset {
					position: relative;
					gap: 0.5rem;

					input,
					.input,
					select,
					.select {
						border: none !important;
						border-radius: 0.25rem;
						border-color: var(--c1-dark) !important;
						font-size: 0.875rem;
						color: var(--white) !important;
						background-color: var(--c1-dark) !important;
						box-shadow: none !important;

						&:active,
						&:focus,
						&:hover {
							color: var(--white) !important;
							background-color: var(--c1-dark) !important;
						}

						&::placeholder {
							font-size: 0.875rem;
							color: rgba(white, 0.5);
						}
					}

					&.flag {
						.image {
							width: fit-content;
							display: inline-flex;
							align-items: center;
							justify-content: center;

							img,
							svg {
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
							}
						}
					}

					&.radio-wrapper {
						width: 100%;
						margin: 0.5rem 0;

						input {
							&:checked {
								& ~ label {
									&::before {
										background-color: var(--white);
									}
								}
							}
						}

						label {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 0.5rem;

							&::before {
								content: "";
								width: 1rem;
								height: 1rem;
								border-radius: 50%;
								border: 0.125rem solid var(--white);
							}
						}
					}
				}

				.form-group {
					width: 100%;
					margin: 0 !important;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					gap: 1rem;

					.fieldset {
						width: unset;
						flex-basis: 0;
						flex-grow: 1;
						flex-shrink: 1;
					}
				}

				.help-text,
				.error {
					width: 100%;
					margin: 0 !important;
					padding: 0 !important;
					position: relative;
					inset: unset;
					display: inline-block;
					text-align: left;
					font-size: 0.75rem;
					font-weight: 400;
				}

				button,
				.btn {
					width: 100%;
					margin: 0 !important;
					font-size: 1rem;
					border: none !important;
					color: var(--c0-dark);
					background-color: var(--c2-dark);
					box-shadow: none !important;

					&:active,
					&:focus,
					&:hover {
						color: var(--c0-dark);
						background-color: var(--c2-dark);
					}
				}
			}

			.plan-op-wrapper {
				width: 100%;
				display: flex;
				align-items: stretch;
				justify-content: space-between;
				gap: 1rem;
				border-radius: 0.25rem;
				background-color: var(--c1-dark);

				.content {
					width: auto;
					padding: 2rem 1rem;
					display: flex;
					flex-flow: wrap column;
					gap: 2rem;
				}

				.actions-wrapper {
					max-width: 12.5rem;
					padding: 0 1rem 1.5rem 1rem;
					position: relative;
					display: flex;
					flex-flow: wrap column;
					align-items: flex-end;
					justify-content: space-between;

					.price {
						width: 6.25rem;
						padding: 1rem 0.5rem 1.5rem 0.5rem;
						position: relative;
						display: flex;
						flex-flow: wrap column;
						text-align: center;
						color: var(--c0-dark);
						background-color: var(--white);
						clip-path: polygon(0% 0%, 100% 0, 100% 80%, 50% 100%, 0 80%);

						span {
							width: 100%;
							display: block;

							&.value {
								font-size: 1.375rem;
								font-weight: 700;
							}
						}
					}

					button,
					.btn {
						margin: 0 !important;
						padding: 0.625rem 1rem;
						font-size: 0.875rem;
						border: none !important;
						color: var(--c0-dark);
						background-color: var(--c2-dark);
						box-shadow: none !important;

						&:active,
						&:focus,
						&:hover {
							color: var(--c0-dark);
							background-color: var(--c2-dark);
						}
					}
				}
			}

			.link-wrapper {
				a,
				button,
				.link {
					width: 100%;
					margin: 0 !important;
					padding: 0 !important;
					text-decoration: underline;
					border: none;
					font-family: "Roboto Flex", sans-serif !important;
					font-size: 0.875rem;
					font-weight: 400;
					color: var(--white);
					background-color: transparent;
					box-shadow: none;

					&:active,
					&:focus,
					&:hover {
						transform: none !important;
						color: var(--white);
						background-color: transparent;
					}
				}
			}

			.image {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img,
				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}
}
