.navbar-top {
	margin: 0.625rem 0 0 0;
	padding: 0.3rem 0;
	background-color: var(--c1);

	.dropdown-menu {
		background-color: var(--c3) !important;

		.dropdown-divider {
			border-color: var(--c1);
		}

		.dropdown-item {
			color: var(--text);
			box-shadow: 0.0187rem 0.0313rem 0.0437rem hsl(var(--shadow-color) / 0.34),
				0.025rem 0.05rem 0.0625rem -0.075rem hsl(var(--shadow-color) / 0.34),
				0.0625rem 0.125rem 0.1563rem -0.1563rem hsl(var(--shadow-color) / 0.34);

			i {
				color: var(--c1);
			}

			&:focus,
			&:hover {
				background-color: var(--c3);
			}
		}
	}

	.dropdown-menu-xl {
		width: 23.125rem;
	}

	.nav-link {
		cursor: pointer;
		font-size: 0.875rem;
		padding: 0.625rem !important;

		&.padd-0 {
			padding: 0 !important;
		}

		.notify {
			color: #5bc56d;
		}

		.help {
			color: #4497f1;
		}

		svg {
			margin-left: 0.3125rem;
			position: relative !important;
			top: 0.125rem !important;
			font-size: 1.125rem;
		}
	}

	.avatar {
		width: 3.125rem;
		height: 3.125rem;
		padding: 0;
		display: flex;
		border: 0.125rem solid var(--c1);
		background-color: transparent;

		img.img-profile {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.navbar-dark {
		.navbar-nav {
			.nav-link {
				color: var(--text) !important;
			}
		}
	}
}

.navbar-nav {
	.nav-item {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3rem;

		&.disabled {
			cursor: none !important;
			pointer-events: none !important;
			opacity: 0.3 !important;
		}

		a,
		.nav-link {
			&.active {
				background-color: var(--c1-light);
				padding: 0.765rem !important;
				border-radius: 50%;
			}
		}
	}
}

.main-content {
	.navbar-top {
		.container-fluid {
			padding-right: 0.75rem !important;
		}
	}
}

@media (max-width: 56.25rem) {
	.navbar-top {
		.navbar-nav {
			.nav-link {
				& > span {
					display: none;
				}
			}
		}
	}
}
