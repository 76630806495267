[color-scheme="light"] {
	.survey {
		.questions-wrapper {
			.questions-nav {
				.items-wrapper {
					.item {
						--background: #e2e2dd;
					}
				}
			}

			.alternatives-wrapper {
				--background: #e2e2dd;
			}
		}
	}
}

.survey {
	--active-color: var(--c3-dark);
	--active-bg: var(--c2);

	width: 100%;
	display: flex;
	flex-flow: nowrap row;
	align-items: stretch;
	justify-content: flex-start;
	gap: 2.625rem;
	color: var(--text);

	.nav-indicators {
		width: fit-content;
		min-width: 14rem;
		height: auto;
		padding: 2rem 1.5rem;
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: flex-start;
		gap: 2rem;
		border-radius: 0.75rem;
		background-color: var(--c3);

		.progress-bar-status,
		.indicator {
			width: 100%;
		}

		.progress-bar-status {
			display: flex;
			flex-flow: wrap column;
			gap: 0.75rem;
			font-size: 1.125rem;

			.progress {
				height: 0.75rem;
				margin: 0 !important;
				border-radius: 0.625rem;

				.progress-bar {
					background-color: var(--c2);
				}
			}
		}

		.indicator {
			display: flex;
			flex-flow: nowrap row;
			align-items: center;
			justify-content: flex-start;
			gap: 1rem;
			border: none !important;
			background-color: transparent !important;
			box-shadow: none !important;

			* {
				pointer-events: none;
			}

			&-icon {
				width: 3.125rem;
				height: 3.125rem;
				position: relative;
				overflow: hidden;
				display: flex;
				border-radius: 50%;

				img,
				svg {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			&-description {
				font-size: 1.125rem;
			}

			&-check-icon {
				display: none;
				justify-content: flex-end;
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;

				&.completed {
					display: flex;
					color: var(--c2);
				}
			}

			.indicator-icon {
				border: solid 0.0625rem;
			}

			&:hover {
				.indicator-description {
					font-weight: 700;
				}
			}
		}
	}

	.questions-wrapper {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		max-width: 100%;
		height: auto;
		padding: 2rem 2.5rem;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 2rem;
		border-radius: 0.75rem;
		background-color: var(--c3);

		.questions-nav {
			width: 100%;
			padding: 1rem;
			position: relative;
			overflow-x: auto;
			display: block;
			border-radius: 0.25rem;

			.items-wrapper {
				height: auto;
				position: relative;
				overflow: hidden;
				display: inline-flex;
				justify-content: flex-end;
				gap: 2rem;

				.item {
					--background: hsla(0, 0%, 100%, 25%);
					min-width: 12rem;
					margin: 0 !important;
					padding: 0.75rem 1rem !important;
					position: relative;
					border-radius: 2.5rem;
					border: none !important;
					font-size: 1rem;
					font-weight: 700;
					color: var(--text);
					background: var(--background);
					box-shadow: none !important;
					transition: 0.35s ease-in-out;

					&.is-active {
						color: var(--active-color);
						background-color: var(--active-bg);
					}

					&:hover {
						--background: hsla(0, 0%, 100%, 45%);
					}
				}
			}
		}

		.description {
			font-family: "Futura", sans-serif !important;
			font-size: 2rem;
			font-weight: 700;
		}

		.label {
			font-size: 1.125rem;
			font-weight: 400;
		}

		.alternatives-wrapper {
			--background: hsla(0, 0%, 0%, 50%);
			width: 100%;
			padding: 2.625rem;
			position: relative;
			overflow: auto hidden;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 2rem;
			border-radius: 0.625rem;
			background-color: var(--background);

			input {
				width: 0 !important;
				height: 0 !important;
				position: absolute !important;
				display: none !important;
				visibility: hidden !important;

				&:checked + label {
					color: var(--active-color) !important;
					background-color: var(--active-bg) !important;
				}
			}

			label {
				margin: 0 !important;
				padding: 0 !important;
				position: relative !important;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				font-size: 1.125rem !important;
				font-weight: 700 !important;
				color: var(--text) !important;
				background-color: var(--c3);
				transition: background-color 0.16s ease-in-out;
			}

			&.sa {
				.input-wrapper {
					label {
						width: 2.875rem;
						height: 2.875rem;
						border-radius: 50%;
					}
				}
			}

			&.habits {
				.input-wrapper {
					label {
						width: max-content;
						min-width: 6.25rem;
						padding: 0.75rem !important;
						border-radius: 0.5rem;
						word-break: keep-all;
					}
				}
			}
		}

		.main-btn {
			width: fit-content !important;
			margin: 0 0 0 auto !important;
			padding: 0.625rem 1.5rem !important;
			border-radius: 0.25rem;
			border: none !important;
			font-size: 1.125rem;
			color: var(--c1-dark) !important;
			background-color: var(--c2) !important;
		}
	}
}

@media (max-width: 100rem) {
	.survey {
		.questions-wrapper {
			.questions-nav {
				.items-wrapper {
					gap: 1rem;
				}
			}

			.alternatives-wrapper {
				justify-content: flex-start;
			}
		}
	}
}

@media (max-width: 80rem) {
	.survey {
		flex-flow: wrap column;

		.nav-indicators {
			width: 100%;
			overflow: auto hidden;
			display: inline-flex;
			flex-flow: nowrap row;

			.progress-bar-status {
				display: none;
			}

			.indicator {
				width: unset;
				min-width: fit-content;
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		.questions-wrapper {
			width: 100%;
			flex: none;
		}
	}
}
