.SignUp {
	.react-datepicker__navigation-icon {
		top: 0.625rem;
	}

	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow,
	.react-datepicker__month-year-read-view--down-arrow,
	.react-datepicker__navigation-icon::before {
		border-color: #000;
	}

	.grup {
		width: 25rem;
	}

	.container-term {
		display: flexbox;

		.term {
			// margin-top: 1rem;
			color: var(--c2) !important;
			cursor: pointer;
		}
	}

	.chackbox-container {
		color: var(--c1);
		display: flex;
		align-items: center;
		gap: 1rem;
		label {
			font-size: 1.2rem;
		}

		input[type="checkbox"] {
			width: 1.5rem;
			height: 1.2rem;
		}
	}
}

.react-datepicker {
	.react-datepicker__navigation {
		width: 10% !important;
	}
}
