.CardPurchaseConfirmation {
	margin-bottom: 3rem;

	.cards {
		width: 100%;
		padding: 3rem 4rem;
		border-radius: 1rem;
		font-size: 1.375rem;
		color: var(--white);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		.top-subdate {
			margin-top: 8rem !important;
		}

		.title {
			font-size: 2rem;
			margin-top: 3rem;
		}

		.text {
			font-size: 1.875rem;
			font-weight: 600;
		}

		.info {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.info .badge-success {
			color: #23221f;
			background-color: #aecd58;
		}
	}

	button {
		border-color: var(--c2) !important;
		color: var(--c1-dark) !important;
		background-color: var(--c2) !important;
		font-size: 1.5rem;
		margin-top: 1rem;
	}

	@media (max-width: 75rem) {
		.cards {
			padding: 4rem 3rem 6rem 3rem;

			.title {
				font-size: 1.5rem;
				margin-top: 1rem;
				padding: 0 !important;
			}

			.text,
			.info {
				font-size: 1rem;
			}

			.sub-title {
				font-size: 1rem;
			}

			.top-subdate {
				margin-top: 6rem !important;

				.text,
				.info {
					font-size: 1rem;
				}
			}
		}

		button {
			font-size: 1.125rem;
		}
	}
}
