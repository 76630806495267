.TableListUsersAdmin {
	background: #ffffff;
	border-radius: 8px !important;
    padding: 10px;

	.rdt_TableRow {
		border: 0;
	}

	.rdt_TableCol {
		border-bottom: 3px solid #171120;
		font-size: 14px;
		font-family: "Roboto", sans-serif;
        font-weight: bold;
	}
	.rdt_TableCell {
		font-size: 14px;
		font-family: "Roboto", sans-serif;
	}

	.header-plans {
		padding: 10px;
		h1 {
			// padding: 0;
			margin: 0;
			font-family: "Roboto";
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 21px;
		}
	}
}
