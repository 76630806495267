.PerformanceAdmin {
	padding: 1rem;

    >.container-fluid > div > div {
        margin-top: 60px;
    }

    .navigate {
		// width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		button {
			border-color: var(--c2) !important;
			color: var(--c1-dark) !important;
			background-color: var(--c2) !important;
			font-size: 1.125rem;

			&:focus,
			&:hover,
			&:active {
				border-color: var(--c2) !important;
				color: var(--c1-dark) !important;
				background-color: var(--c2) !important;
			}
		}
	}

	.bg-uelf {
		background-size: contain !important;
		background-position: center center !important;
		background-repeat: no-repeat !important;
	}
}
