$circle-count: 13;
$loader-proportion: 6.25rem;
$animation_config: (
	name: spin,
	duration: 1s,
	timing: ease-in-out,
	iteration: infinite,
	direction: alternate,
	fill-mode: both,
);

@function sh-setup($config) {
	@return zip(map-values($config)...);
}

.LoaderJourneyEducation {
	width: 100%;
	min-height: 25vmax;
	padding: 1rem;
	position: relative;
	display: flex;
	flex-flow: wrap column;
	align-items: center;
	justify-content: center;
	gap: 2rem;

	.counter {
		position: relative;

		&-loader {
			position: absolute;
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			word-break: keep-all;
			font-size: 2.25rem;
			color: var(--c2);
		}
	}

	.loader {
		animation: sh-setup($animation_config);
		height: $loader-proportion;
		position: relative;
		width: $loader-proportion;

		svg {
			animation: sh-setup($animation_config);
			position: absolute;
			top: -50%;
			left: 0;
			transform-origin: bottom center;
			fill: var(--c2);
		}
	}

	@for $i from 1 through $circle-count {
		.loader svg:nth-of-type(#{$i}) {
			transform: rotate(($i * (360/ ($circle-count - 1))) + deg);
		}
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
