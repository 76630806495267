.ModalTrail {
	iframe {
		width: 100%;
		height: 40rem;
	}
}

@media (max-width: 47rem) {
	.ModalTrail {
		iframe {
			width: 100%;
			height: 25rem;
		}
	}
}

@media (min-width: 30.1rem) and (max-width: 47rem) {
	.ModalTrail {
		iframe {
			width: 100%;
			height: 30rem;
		}
	}
}

@media (min-width: 47.1rem) and (max-width: 60rem) {
	.ModalTrail {
		iframe {
			width: 100%;
			height: 32rem;
		}
	}
}

@media (min-width: 60.1rem) and (max-width: 80rem) {
	.ModalTrail {
		iframe {
			width: 100%;
			height: 40rem;
		}
	}
}
