.CardResourceAdmin {
    & > .card{
        padding: 20px;
        
        & .title {
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            font-family: "Roboto Flex" !important;
            // width: 30rem;
        }
        
        & .subtitle {
            font-family: "Roboto Flex" !important;
            color: var(--text-light);
            font-style: normal;
            font-size: 34px;
            font-weight: 500;
            margin-top: -30px;
        }

        .graphic {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                position: absolute;
                top: calc(50% - 2.5rem);
                text-align: center;
                font-size: 3.4rem;
                font-weight: 600;
                width: 100%;
                color: var(--text);
                animation: fadeIn 2s;
            }
        }

        .image {
            // width: 4.5rem;
            height: 4.5rem;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            display: flex;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .subresources {
            gap: 2rem;
            .item-title {
                margin: 1rem !important;
                color: var(--text-light);
            }

            
            >.item {
                background-color: var(--c1-light);
                border-radius: 10px;
                text-align: center;
                // width: 15rem;
                >.graphic {
                    padding: 20px;
                    p{
                        top: calc(50% - 1rem);
                        font-size: 2rem !important;
                        color: var(--text-light);
                    }
                }
            }
        }
    }

    #canvas-container {
        height: 60vh;
        width: 60vw;
        position: relative;
     }
    
     @media (min-width: 768px) {
         #canvas-container {
             height: auto;
             width: auto;
          }
     }
}