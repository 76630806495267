.modal-change-password {
    font-family: 'Roboto', sans-serif !important;
    
    .container {
        padding-right: 100px;
        padding-left: 100px;

        padding-top: 50px;
        padding-bottom: 50px;

        .brand {
            padding: 0 0 2rem 0;
        }

        .message {
            h1 {
                color: var(--c2-dark);
            }
    
            h2 {
                margin-top: -15px;
                font-size: 1.5rem;
                color: white;
            }
        }
        
        .text {
            text-align: center;
            margin-top: 20px;
            font-size: 0.8rem;
            p {
                margin-top: -20px;
            }
        }

        .image {
            width: 100%;
            display: flex;
            align-items: left;
            justify-content: left;
    
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: left;
            }
    
            &.cover {
                height: 100%;
    
                img {
                    object-fit: cover;
                }
            }
        }
    }

}