.macro-goals {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 2rem;

	.image {
		width: 100%;

		img,
		svg {
			width: 100%;
		}
	}

	.items {
		width: 100%;
	}

	.actions {
		width: 100%;
		display: flex;
		flex-flow: nowrap row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;
	}

	button {
		margin: 0 !important;
		border-color: var(--c2);
		color: var(--c1);
		background-color: var(--c2);

		&:focus,
		&:focus-within,
		&:hover,
		&:active {
			border-color: var(--c2);
			color: var(--text);
			background-color: var(--c1);
		}

		&.floating-button {
			position: fixed;
			right: 2rem;
			bottom: 0.3rem;
			z-index: 1;
		}
	}
}

@media (max-width: 56.25rem) {
	.macro-goals {
		.actions {
			flex-flow: wrap column;
		}
	}
}
