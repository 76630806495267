.CardSurveyFinish {
	width: 100%;
	min-height: 100vh;
	display: flex;
	padding: 3.75rem 2.5rem;
	position: relative;
	border-radius: 0.75rem;
	background-color: var(--c3);

	.wrapper {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		display: flex;
		flex-flow: nowrap row;
		align-items: stretch;
		justify-content: flex-start;
		gap: 0;
		border-radius: 0.625rem;

		.content {
			width: 50%;
			height: auto;

			&.text {
				padding: 2.5rem 3.75rem;
				display: flex;
				flex-flow: wrap column;
				align-items: center;
				justify-content: center;
				gap: 3rem;
				color: var(--c1-dark);
				background-color: var(--c2);

				.icon {
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;

					img,
					svg {
						width: 4.25rem;
						height: 4.25rem;
						object-fit: contain;
						object-position: center;
					}
				}

				.title {
					width: 100%;
					text-align: left;
				}

				.text {
					width: 100%;
					text-align: left;

					* {
						font-size: inherit;
					}
				}
				.actions {
					width: 100%;
					display: flex;
					flex-flow: wrap column;
					align-items: flex-end;

					button {
						width: fit-content;
						min-width: 60%;
						border-color: var(--white) !important;
						font-size: 1.125rem !important;
						font-weight: 700;
						color: var(--c1-dark) !important;
						background-color: var(--white) !important;

						&:hover,
						&:focus {
							border-color: var(--white) !important;
							color: var(--c1-dark) !important;
							background-color: var(--white) !important;
						}
					}
				}
			}

			&.image {
				background-image: url("https://imgur.com/cxwcbGQ.png");
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}

@media (max-width: 64rem) {
	.CardSurveyFinish {
		min-height: auto;
		padding: 0.9375rem;

		.wrapper {
			flex-flow: wrap column-reverse;

			.content {
				width: 100%;
				padding: 0.9375rem !important;
				gap: 1rem !important;

				.icon {
					position: relative;
					display: flex;
					align-items: center !important;
					justify-content: center !important;
					height: 4.375rem;
				}

				.title {
					font-size: 1.5rem !important;
				}

				&.image {
					height: 12.5rem;
					background-attachment: fixed;
					background-position: center top;
					display: none;
				}

				.actions {
					button {
						min-width: 100% !important;
					}
				}
				&.text {
					.title {
						font-size: 2rem;
					}

					.text {
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}
