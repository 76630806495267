.DashboardTrackScore {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	gap: 2rem;

	&.card {
		width: 100%;
		margin: 0;
		padding: 2rem;
		position: relative;
		display: flex;
		flex-flow: wrap column;
		gap: 1rem;
		border: none;
		border-radius: 0.375rem;
		color: var(--text);
		background-color: var(--c3);
		box-shadow: none;

		.title {
			width: 100%;
			margin: 0;
			position: relative;
			display: block;
			text-align: left;
			font-size: 1.5rem;
			font-weight: 700;
			color: inherit;
		}

		.subtitle {
			width: 100%;
			margin: 0;
			position: relative;
			display: block;
			text-align: left;
			font-size: 1.125rem;
			color: inherit;
		}

		.empty-message {
			display: flex;
			align-items: center;
			gap: 0.75rem;
		}
	}
}
