.checkbox-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;

	input[type="checkbox"] {
		width: 0;
		height: 0;
		position: absolute;
		visibility: hidden;

		&:checked + label {
			color: var(--white);
			background-color: #859b60;

			.icon {
				opacity: 1;
			}
		}
	}

	label {
		width: 100%;
		padding: 0.75rem 2rem;
		position: relative;
		user-select: none;
		cursor: pointer;
		border-radius: 2.5rem;
		text-align: center;
		font-family: inherit;
		font-size: 0.875rem;
		font-weight: 500;
		color: var(--white);
		background-color: #51701c;
		transition-property: color, background-color;
		transition-duration: 0.15s;
		transition-timing-function: ease-in;

		.icon {
			opacity: 0;
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			left: 1rem;
		}

		&:focus,
		&.is-active {
			background-color: var(--c1);
		}

		&:not(:focus):not(.is-active) {
			&:hover {
				color: var(--c2);
			}
		}
	}
}
