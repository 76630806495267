.goals {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 2.625rem;
}

.background-goals {
	padding: 24px !important;
    border-radius: 0.75rem;
    background-color: var(--c3);
}
