@charset "UTF-8";
@use "sass:math";

[color-scheme="light"] {
	.journey-backpack-item {
		.card {
			--bg-card: #fff;
			color: #44443a;

			.journey-backpack-item-row {
				&.title-row {
					--bg-top: #8b8a79;
				}
			}
		}
	}
}

[color-scheme="dark"] {
	.journey-backpack-item {
		.card {
			--bg-card: #23221f;

			.journey-backpack-item-row {
				&.title-row {
					--bg-top: #161515;
					border-bottom: 0.0625rem solid #e9ecef78;
				}
				&.content-row {
					border-bottom: 0.0625rem solid #e9ecef78;

					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}

.JourneyBackpack {
	.journey-backpack-item {
		--shadow-color: 54deg 13% 10%;
		--shadow-elevation-low: 0.0187rem 0.0313rem 0.0437rem
				hsl(var(--shadow-color) / 0.34),
			0.025rem 0.05rem 0.0625rem -0.075rem hsl(var(--shadow-color) / 0.34),
			0.0625rem 0.125rem 0.1563rem -0.1563rem hsl(var(--shadow-color) / 0.34);
		--shadow-elevation-medium: 0.0187rem 0.0313rem 0.0437rem
				hsl(var(--shadow-color) / 0.36),
			0.05rem 0.1rem 0.125rem -0.05rem hsl(var(--shadow-color) / 0.36),
			0.1313rem 0.2562rem 0.325rem -0.1062rem hsl(var(--shadow-color) / 0.36),
			0.3125rem 0.625rem 0.7875rem -0.1563rem hsl(var(--shadow-color) / 0.36);

		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden auto;

		&.card {
			background-color: var(--c14);
			padding: 0;
			box-shadow: none;
		}

		.form-check {
			width: 100%;
			max-width: 100%;
			margin: 0 !important;
			padding: 0;
			position: relative;
			overflow: hidden;
			display: flex;
			flex-flow: nowrap row;
			gap: 0;
		}

		.input-wrapper {
			width: 4rem;
			max-width: 0;
			height: 100%;
			display: flex;
			flex-flow: wrap column;
			align-items: center;
			justify-content: center;
			gap: 1rem;
		}

		label {
			width: 100%;
			overflow: auto hidden;
			display: block;
			color: var(--white) !important;
		}

		.card {
			--bg: var(--bg-card);

			margin: 0;
			padding: 0;
			position: relative;
			transform: translateY(0);
			display: flex;
			flex-flow: wrap column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0;
			background-color: var(--bg-card);
			box-shadow: var(--shadow-elevation-low);
			transition-property: transform, box-shadow;
			transition-duration: 0.16s;
			transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
			width: 100%;
		}

		&-row {
			width: 100%;
			padding: 1rem 2rem;
			display: flex;
			flex-flow: wrap row;
			align-items: center;
			justify-content: center;
			gap: 2rem;

			&.title-row {
				border-radius: 0.375rem 0.375rem 0 0;
				background-color: var(--bg-top);
				color: var(--white);
			}

			&.content-row {
				border-radius: 0;
				background-color: transparent;
				transition-property: background-color;
				transition-duration: 0.32s;
				transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
				border-bottom: 0.0625rem solid #e9ecef;

				&:last-child {
					border-bottom: none;
				}

				.select {
					width: 100%;
					max-width: 100%;
					padding: 0.3125rem;
					border-radius: 0.5rem;
					border: 0.0625rem solid #e9ecef;
					font-size: 0.875rem;
					color: var(--white) !important;
					caret-color: var(--white) !important;
					background-color: var(--c2) !important;
					transition: all 0.35s ease-in;
					max-height: 3.625rem;
				}
			}

			&.is-gapless {
				gap: 0;
			}
		}

		&-col {
			height: 100%;
			flex-basis: 0;
			flex-grow: 1;
			flex-shrink: 1;
			font-size: 1.3rem;
			display: flex;
			flex-flow: wrap column;
			align-items: flex-start;
			justify-content: center;
			gap: 1rem;
			padding: 0.3125rem;

			@for $i from 0 through 12 {
				&.is-#{$i} {
					flex: none;
					width: percentage(math.div($i, 12));
				}
			}

			button {
				width: 100%;
				margin: 0 0 0 auto;
				box-shadow: none !important;
			}
		}

		.title {
			width: 100%;
			line-height: 1.5;
			text-align: left;
			font-size: 1.5rem;
			font-weight: 600;
		}

		.mb-label {
			width: 100%;
			display: none;
			text-align: left;
			font-size: 1.15rem;
			font-weight: 700;
		}
	}

	.actions {
		width: 100%;
		display: flex;
		flex-flow: nowrap row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;
		margin-bottom: 3rem;
		margin-top: 3rem;

		button {
			margin: 0 !important;
			color: var(--white) !important;
			border-color: var(--c2) !important;
			background-color: var(--c2) !important;

			&.floating-button {
				position: fixed;
				bottom: 2rem;
				right: 2rem;
				z-index: 1;
			}
		}
		button:hover {
			color: var(--c1-light) !important;
			border-color: var(--c2) !important;
			background-color: var(--c1-dark) !important;
		}
	}
}

@media screen and (max-width: 70rem) {
	.journey-backpack-item {
		&-row {
			padding: 2rem;
			flex-flow: wrap column;

			&.title-row {
				display: none;
			}

			&.content-row {
				border-radius: 0.375rem;
			}
		}

		&-col {
			width: 100% !important;
			gap: 0rem;
			flex-basis: fit-content !important;

			@for $i from 0 through 12 {
				&.is-#{$i} {
					width: 100%;
				}
			}
		}

		.card {
			.mb-label {
				display: block;
			}
		}
	}
}
