@import "./styles/argon-dashboard-pro-react.scss";
@import "~bootstrap/scss/bootstrap";

* {
	-webkit-tap-highlight-color: transparent;

	&::selection {
		color: var(--selection-color);
		background-color: var(--selection-background-color);
	}

	&::-webkit-scrollbar {
		width: 1.125rem;
		height: 1.125rem;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border: none;
		background-color: var(--c1);
		box-shadow: none;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 0.57rem;
		border: 0.35rem solid var(--c1);
		background-color: var(--c2);
	}
}

:root {
	--base-size: 100%;

	--radius-small: 0.25rem;
	--radius-medium: 0.5rem;
	--radius-large: 1rem;

	--row-gap: 1rem;

	--white: hsl(0, 0%, 100%);
	--black: hsl(0, 0%, 0%);

	--error: hsl(356, 57%, 50%);

	--c0-dark: hsl(45, 6%, 13%);
	--c1-dark: 	hsl(0, 0%, 15%);
	--c2-dark: hsl(76, 54%, 57%);
	--c3-dark: hsl(53, 8%, 21%);
	--c4-dark: hsla(82, 55%, 44%, 1);
	--c5-dark: hsla(82, 66%, 31%, 1);
	--c6-dark: 	hsla(82, 70%, 21%, 1);
	--c7-dark: hsla(81, 81%, 14%, 1);
	--c8-dark: hsl(356, 57%, 50%);
	--c9-dark: hsl(273, 38%, 35%);
	--c10-dark: hsl(199, 55%, 59%);
	--c11-dark: hsl(26, 67%, 59%);
	--c12-dark: hsl(76, 54%, 57%);
	--c13-dark: hsl(61, 100%, 86%);
	--c14-dark: hsl(40, 2%, 30%);
	--c15-dark: hsl(53, 8%, 21%);
	--c16-dark: hsl(0, 2%, 8%);
	--c17-dark: hsl(0, 86%, 75%);
	--c18-dark: hsl(238, 89%, 56%);
	--c19-dark: hsl(356deg 89% 55%);

	--sidenav-dark: hsl(45, 6%, 13%);
	--heading-dark: hsl(76, 54%, 57%);
	--text-dark: 	hsl(0, 0%, 99%);

	--heading-title-dark: hsl(0, 0%, 100%);
	--heading-title-white: hsl(76, 54%, 57%);

	--range-handle-color-dark: hsl(53, 8%, 21%);
	--range-handle-color-hover-dark: hsl(53, 8%, 42%);
	--range-track-color-dark: 	hsl(57, 7%, 51%);


	--c1-light: hsl(60, 7%, 92%);
	--c2-light: hsl(76, 54%, 57%);
	--c3-light: hsl(0, 0%, 100%);
	--c4-light: hsl(76, 54%, 57%);
	--c5-light: hsl(82, 42%, 48%);
	--c6-light: hsl(82, 38%, 39%);
	--c7-light: hsl(82, 36%, 32%);
	--c8-light: hsl(356, 57%, 50%);
	--c9-light: hsl(273, 38%, 35%);
	--c10-light: hsl(199, 55%, 59%);
	--c11-light: hsl(26, 67%, 59%);
	--c12-light: hsl(76, 54%, 57%);
	--c13-light: hsl(61, 100%, 86%);
	--c14-light: hsl(0, 0%, 97%);
	--c15-light: hsl(53, 8%, 21%);
	--c16-light: hsl(0, 2%, 8%);
	--c17-light: hsl(0, 86%, 75%);
	--c18-light: hsl(238, 89%, 56%);
	--c19-light: hsl(356deg 89% 55%);

	--sidenav-light: hsl(57, 7%, 51%);
	--heading-light: hsl(57, 7%, 51%);
	--text-light: hsl(57, 7%, 51%);
	--nav-bg: hsl(270, 43%, 28%);

	--range-handle-color-light: hsl(53, 8%, 21%);
	--range-handle-color-hover-light: hsl(53, 8%, 42%);
	--range-track-color-light: hsl(83, 72%, 45%);

	--selection-color: hsl(0, 0%, 20%) !important;
	--selection-background-color: hsl(76, 72%, 67%);
}

@media (prefers-color-scheme: dark) {
	:root {
		color-scheme: dark;

		--c1: var(--c1-dark);
		--c2: var(--c2-dark);
		--c3: var(--c3-dark);
		--c4: var(--c4-dark);
		--c5: var(--c5-dark);
		--c6: var(--c6-dark);
		--c7: var(--c7-dark);
		--c8: var(--c8-dark);
		--c9: var(--c9-dark);
		--c10: var(--c10-dark);
		--c11: var(--c11-dark);
		--c12: var(--c12-dark);
		--c13: var(--c13-dark);
		--c14: var(--c14-dark);
		--c15: var(--c15-dark);
		--c16: var(--c16-dark);
		--c17: var(--c17-dark);
		--c18: var(--c18-dark);
		--c19: var(--c19-dark);

		--sidenav: var(--sidenav-dark);
		--heading: var(--heading-dark);
		--heading-title: var(--heading-title-dark);

		--text: var(--text-dark);

		--range-handle-color: var(--range-handle-color-dark);
		--range-handle-color-hover: var(--range-handle-color-hover-dark);
		--range-track-color: var(--range-track-color-dark);
	}
}

@media (prefers-color-scheme: light) {
	:root {
		color-scheme: light;

		--c1: var(--c1-light);
		--c2: var(--c2-light);
		--c3: var(--c3-light);
		--c4: var(--c4-light);
		--c5: var(--c5-light);
		--c6: var(--c6-light);
		--c7: var(--c7-light);
		--c8: var(--c8-light);
		--c9: var(--c9-light);
		--c10: var(--c10-light);
		--c11: var(--c11-light);
		--c12: var(--c12-light);
		--c13: var(--c13-light);
		--c14: var(--c14-light);
		--c15: var(--c15-light);
		--c16: var(--c16-light);
		--c17: var(--c17-light);
		--c18: var(--c18-light);
		--c19: var(--c19-light);

		--sidenav: var(--sidenav-light);
		--heading: var(--heading-light);
		--heading-title: var(--heading-title-white);

		--text: var(--text-light);

		--range-handle-color: var(--range-handle-color-light);
		--range-handle-color-hover: var(--range-handle-color-hover-light);
		--range-track-color: var(--range-track-color-light);
	}
}

[color-scheme="dark"] {
	color-scheme: dark;

	--c1: var(--c1-dark);
	--c2: var(--c2-dark);
	--c3: var(--c3-dark);
	--c4: var(--c4-dark);
	--c5: var(--c5-dark);
	--c6: var(--c6-dark);
	--c7: var(--c7-dark);
	--c8: var(--c8-dark);
	--c9: var(--c9-dark);
	--c10: var(--c10-dark);
	--c11: var(--c11-dark);
	--c12: var(--c12-dark);
	--c13: var(--c13-dark);
	--c14: var(--c14-dark);
	--c15: var(--c15-dark);
	--c16: var(--c16-dark);
	--c17: var(--c17-dark);
	--c18: var(--c18-dark);
	--c19: var(--c19-dark);

	--background-input: hsla(0, 0%, 99%, 0.3);

	--sidenav: var(--sidenav-dark);
	--heading: var(--heading-dark);
	--text: var(--text-dark);

	--range-handle-color: var(--range-handle-color-dark);
	--range-handle-color-hover: var(--range-handle-color-hover-dark);
	--range-track-color: var(--range-track-color-dark);
}

[color-scheme="light"] {
	color-scheme: light;

	--c1: var(--c1-light);
	--c2: var(--c2-light);
	--c3: var(--c3-light);
	--c4: var(--c4-light);
	--c5: var(--c5-light);
	--c6: var(--c6-light);
	--c7: var(--c7-light);
	--c8: var(--c8-light);
	--c9: var(--c9-light);
	--c10: var(--c10-light);
	--c11: var(--c11-light);
	--c12: var(--c12-light);
	--c13: var(--c13-light);
	--c14: var(--c14-light);
	--c15: var(--c15-light);
	--c16: var(--c16-light);
	--c17: var(--c17-light);
	--c18: var(--c18-light);
	--c19: var(--c19-light);

	--background-input: var(--c1-light);

	--sidenav: var(--sidenav-light);
	--heading: var(--heading-light);
	--text: var(--text-light);

	--range-handle-color: var(--range-handle-color-light);
	--range-handle-color-hover: var(--range-handle-color-hover-light);
	--range-track-color: var(--range-track-color-light);
}

html,
body {
	margin: 0;
	padding: 0;
	word-break: break-word;
}

html {
	font-size: var(--font-size);
}

body {
	font-family: "Roboto Flex", sans-serif !important;
	color: var(--text);
	background-color: var(--c1);

	@media (prefers-reduced-motion: no-preference) {
		scroll-behavior: smooth;
	}
}

.futura {
	font-family: "Futura PT", sans-serif !important;
}

.title {
	font-family: "Futura PT", sans-serif !important;
	font-size: 2rem;
	font-weight: 700;
}

.text {
	font-size: 1.125rem;
	font-weight: 400;
	font-weight: 700;
}

button,
.btn {
	word-break: keep-all;
	font-family: "Futura PT", sans-serif !important;
	font-size: 1.25rem;
	font-weight: 700;
}

.dropdown-menu {
	color: var(--white) !important;
	background-color: var(--c1) !important;

	.dropdown-divider {
		border-color: var(--text) !important;
	}

	.dropdown-item {
		display: flex !important;
		align-items: center !important;
		justify-content: flex-start !important;
		gap: 0.5rem !important;
		text-align: left;
		color: var(--text);
		box-shadow: var(--bs-m) !important;

		i,
		svg {
			margin: 0 !important;
			color: var(--c2) !important;
		}

		&:focus,
		&:hover {
			background-color: var(--c3) !important;
		}
	}
}

a {
	cursor: pointer;
}

p {
	line-height: 1.5;
	font-size: inherit;
	font-weight: 400;
}

.main-content {
	.container-fluid {
		padding-left: 3rem !important;
		padding-right: 3rem !important;
	}
}

.form-default {
	--content-padding: 0 6.25rem;

	width: 45%;
	padding: 4rem 0;
	flex: none;
	flex-flow: wrap column;
	gap: 1rem;
	background-color: var(--c2);

	.brand {
		padding: var(--content-padding);
		justify-content: flex-start !important;
	}

	.welcome-message {
		width: 100%;
		padding: var(--content-padding);
		display: flex;
		flex-flow: wrap column;
		gap: 0.5rem;
		text-align: left;

		h1,
		h2 {
			width: 100%;
			margin: 0;
			line-height: 1;
		}

		h1 {
			font-size: 1.5rem;
			font-weight: 600;
			color: var(--c1);
		}

		h2 {
			font-size: 0.9rem;
			font-weight: 400;
			color: var(--white);
		}
	}

	.link {
		width: fit-content !important;
		height: auto;
		margin: 0 !important;
		padding: 0 !important;
		display: inline-flex;
		border: none !important;
		background: none !important;
		background-color: none !important;
		font-size: inherit !important;
		color: inherit;

		&.forgot-pass {
			font-size: 0.9rem !important;
		}
	}

	.tabs {
		width: 100%;
		padding: var(--content-padding);
		position: relative;
		display: flex;
		flex-flow: wrap column;
		gap: 0.5rem;

		.links-group {
			width: 100%;
			display: flex;
			flex-flow: nowrap row;
			align-items: stretch;
			justify-content: flex-start;
			gap: 1rem;

			.tablink {
				opacity: 0.25;
				width: fit-content;
				padding: 0;
				border: none;
				font-size: 1.5rem;
				font-weight: 500;
				color: var(--white);
				background-color: transparent;
				transition: all 0.25s ease-in-out;

				&:focus,
				&:hover {
					opacity: 0.75;
					color: var(--c3);
				}

				&:active,
				&.is-active {
					opacity: 1;
					color: var(--c2);
				}
			}
		}

		.tabcontent {
			width: 100%;
			position: relative;
			display: none;

			&.is-active {
				display: flex;
			}
		}
	}
}

form {
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	display: flex;
	flex-flow: wrap column;
	gap: 1rem;

	.fieldset {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		justify-content: flex-start;
		gap: 0.75rem;

		label {
			// width: 100%;
			// margin: 0;
			// padding: 0;
			// position: relative;
			// display: inline-block;
			// flex-basis: 0;
			// flex-grow: 1;
			// flex-shrink: 1;
			// user-select: none;
			// cursor: pointer;
			font-size: 1rem;
			color: var(--c1-light);
			font-style: normal;
			font-weight: 700;
			line-height: 23px;
		}

		.error {
			margin-bottom: 0.5em;
			text-align: left;
			font-size: 1.1rem;
			color: var(--error);
			font-weight: 600;
		}

		input {
			margin: 0;
		}

		input,
		select,
		.input,
		.select {
			--border-color: var(--text) !important;
			--color: var(--text) !important;
			--background-color: var(--background-input);

			width: 100%;
			max-width: 100%;
			max-height: 3.75rem;
			padding: 0.75rem 1rem;
			border-radius: 0.5rem;
			border: 0 solid var(--border-color);
			font-size: 1rem;
			color: var(--color);
			caret-color: var(--color);
			background-color: var(--background-color);
			transition: all 0.35s ease-in;

			&:focus {
				border-color: var(--border-color);
				// background-color: var(--c3) !important;
			}

			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus,
			&:-webkit-autofill:active {
				color: var(--color) !important;
				-webkit-text-fill-color: var(--color) !important;
				// background-color: var(--c1) !important;
				box-shadow: 0 0 0 1.875em var(--c1) inset !important;
			}

			&:disabled {
				opacity: 0.3;
			}

			&.invalid {
				border: 0.125rem solid var(--error);
			}
		}

		.content-selfie {
			flex-flow: row;

			.view-selfie {
				width: 4.75rem;
				height: 4.75rem;
				margin-left: 1rem;
				padding: 0;
				position: relative;
				overflow: hidden;
				display: flex;
				border-radius: 50%;
				border: 0.125rem solid var(--white);
				text-align: center;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}

		.select {
			padding: 0;

			&.disabled {
				opacity: 0.4;
			}
		}

		.help-text {
			width: 100%;
			display: inline-block;
			user-select: none;
			font-size: 1rem;
		}

		&.toggle {
			flex-flow: wrap row;
			align-items: center;

			label.toggle {
				display: flex;
				flex-flow: wrap row;
				align-items: center;
				gap: 1rem;

				input[type="checkbox"].toggle-input {
					width: 0;
					height: 0;
					margin: 0;
					position: absolute;
					visibility: hidden;

					&:checked {
						& ~ .toggle-display {
							&::before {
								transform: translate(100%, -50%);
								background-color: var(--c2-dark);
							}
						}
					}
				}

				.toggle-display {
					--offset: 0.25em;
					--diameter: 1.75em;

					width: calc(var(--diameter) * 2 + var(--offset) * 2);
					height: calc(var(--diameter) + var(--offset) * 2);
					box-sizing: content-box;
					position: relative;
					display: inline-flex;
					align-items: center;
					justify-content: space-around;
					border: none;
					border-radius: 100vw;
					font-size: 1em;
					background-color: var(--c3);
					transition: all 250ms;

					.toggle-icon {
						display: inline-block;
						vertical-align: middle;
						overflow: hidden;
						color: inherit;
						fill: currentcolor;

						&.checked {
							color: var(--c2);
						}

						&.not-checked {
							color: var(--white);
						}
					}

					&::before {
						content: "";
						width: var(--diameter);
						height: var(--diameter);
						box-sizing: border-box;
						will-change: transform;
						position: absolute;
						transform: translate(0, -50%);
						top: 50%;
						left: var(--offset);
						z-index: 2;
						border-radius: 50%;
						background-color: var(--white);
						transition: inherit;
					}
				}

				&:focus,
				&:focus-within {
					outline: 0.0625rem dotted var(--white);
					outline: 0.0625rem auto -webkit-focus-ring-color;
					outline-offset: 0.125rem;
				}
			}
		}
	}

	button.btndefault {
		width: 80%;
		margin: 0;
		padding: 1rem 1.75rem;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		appearance: none;
		cursor: pointer;
		user-select: none;
		outline-style: none;
		outline-offset: 0;
		border-radius: 1.75rem;
		border: none;
		line-height: 1;
		text-decoration: none;
		font-family: inherit;
		font-style: normal;
		font-size: 1rem;
		font-weight: 600;
		color: var(--c1);
		background-color: var(--c2);
		box-shadow: none;
	}
}

.modal {
	.modal-content {
		background-color: var(--c1-dark);
		overflow: hidden auto;

		.modal-header {
			padding: 1.5rem !important;
			align-items: stretch;
			justify-content: flex-start;
			gap: 1rem;
			color: var(--c1) !important;

			.modal-title {
				width: unset;
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				line-height: 1.25;
				text-align: left;
				font-family: "Futura PT", sans-serif !important;
				font-size: 1.5rem !important;
				font-weight: 700;
				color: var(--c1-light) !important;
			}

			.close > span:not(.sr-only) {
				height: 100%;
				margin: 0 !important;
				padding: 0 !important;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--c2) !important;

				* {
					pointer-events: none;
				}
			}
		}

		.modal-body {
			padding: 1.5rem 2.5rem !important;
			display: flex;
			flex-flow: wrap column;
			gap: 1rem;
			color: var(--c1-light);

			.title {
				font-size: 1.25rem !important;
				color: var(--c1-light);

				&.p {
					font-size: 1.875rem !important;
				}

				&.s {
					font-size: 1.5rem !important;
				}

				&.t {
					font-size: 1.125rem !important;
				}
			}

			.text {
				width: 100%;
				line-height: 1.75;
				text-align: left;
				font-size: 1rem !important;
			}
		}

		.modal-footer {
			.btn {
				font-size: 1rem;

				&.btn-primary {
					border-color: var(--c2) !important;
					color: var(--c1) !important;
					background-color: var(--c2) !important;
				}
				&.btn-primary:hover {
					border-color: var(--c2) !important;
					color: var(--c1-light) !important;
					background-color: var(--c1-dark) !important;
				}
				&.btn-secondary:hover {
					border-color: var(--c1) !important;
					color: var(--c1-light) !important;
					background-color: var(--c1-dark) !important;
				}
			}
		}
	}
}

.modal-backdrop {
	&.show {
		opacity: 1 !important;
		background-color: hsla(0, 0%, 0%, 0.75);
		backdrop-filter: grayscale(100%) blur(0.3125rem);
	}
}

.actions-wrapper {
	width: 100%;
	display: flex;
	flex-flow: nowrap row;
	align-items: center;
	justify-content: flex-end;
	gap: 1.5rem;

	button {
		width: fit-content;
		margin: 0 !important;
		font-size: 1.125rem;

		&.step-backward {
			color: var(--c2);
			background-color: var(--c1);
			border: solid 0.0625rem;
			border-color: var(--c2);

			&:active {
				background-color: var(--c1) !important;
				color: var(--c2) !important;
			}
		}

		&.step-forward {
			color: var(--c1);
			background-color: var(--c2);
			border: solid 0.0625rem;
			border-color: var(--c2);

			&:active {
				background-color: var(--c2) !important;
				color: var(--c3) !important;
			}
		}
	}
}

.btn-close {
	padding: 0.25rem 0.5rem;
	line-height: 1;
	border-radius: 0.375rem;
	border: none;
	font-size: 1.375rem;
	font-weight: 600;
	color: var(--c1-dark);
	background-color: var(--c2);

	&::after {
		content: "\00d7";
	}
}

@media (max-width: 86rem) {
	.title {
		font-size: 1.75rem;
	}

	.text {
		font-size: 1rem;
	}

	button,
	.btn {
		font-size: 1rem;
	}
}

@media (max-width: 64rem) {
	.main-content {
		.container-fluid {
			padding: 0 1rem !important;
		}
	}
}
