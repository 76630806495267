.CardGradesResources {
	--list-color: var(--c2);

	.text {
		font-size: 1rem;
	}

	.square {
		display: flex;
		flex-flow: row;
		gap: 1.5625rem;
		margin-bottom: 1.875rem;

		.content {
			display: grid;
			flex-flow: column;
			gap: 1.5rem;
			width: 100%;
			grid-template-columns: 1fr 1fr;
		}
	}

	.video-container {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
	}

	.video-container {
		width: 100%;

		.player {
			width: 100%;
			position: relative;
			overflow: hidden;
			border-radius: 0.75rem;

			#player {
				width: 100%;
				margin-top: 0.9375rem;
			}
		}
	}

	.piorrecurso {
		&.wrapper {
			width: 100%;
			position: relative;
			display: flex;
			flex-flow: column;
			justify-content: center;

			.introducao {
				width: 100%;
				margin: 0;
				padding: 1.625rem 1.875rem;
				display: flex;
				flex-flow: nowrap row;
				align-items: flex-start;
				gap: 2rem;

				.image {
					img {
						width: 5.625rem;
					}
				}

				.grouping {
					position: relative;
					display: flex;
					flex-flow: wrap column;
					gap: 0.5rem;

					.title {
						&.main {
							font-size: 1.25rem;
						}
					}
				}
			}

			.avaliacao,
			.atividades,
			.aprendizado {
				width: 100%;
				padding: 1.625rem 1.875rem;
				display: flex;
				flex-flow: wrap column;
				gap: 2rem;

				.title {
					color: var(--text);
				}

				p,
				li {
					position: relative;
					color: var(--text);
				}

				ul {
					list-style: none;
					padding-left: 5rem;
					position: relative;
					display: flex;
					flex-flow: wrap column;
					gap: 1rem;

					&.special-list-type {
						li {
							&::marker {
								display: none;
							}

							&::before {
								content: "";
								width: 0.625rem;
								height: 0.625rem;
								position: absolute;
								transform: translate(-50%, -50%);
								top: 50%;
								left: -1rem;
								border-radius: 50%;
								background-color: var(--list-color);
							}
						}

						&::before {
							content: "";
							width: 0.25rem;
							height: calc(100% - 0.625rem);
							position: absolute;
							transform: translate(-50%, -50%);
							top: 50%;
							left: 4rem;
							border-radius: 0.25rem;
							background: var(--c1);
						}
					}
				}
			}

			.aprendizado,
			.learning {
				display: flex;
				flex-flow: nowrap row;
				gap: 2rem;

				.wrapper {
					width: 100%;
					overflow: visible;
					display: flex;
					flex-flow: wrap column;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 1rem;
				}

				ul {
					padding: 2rem;
					list-style: none;

					li {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 1.7rem;

						svg {
							padding: 0.3125rem;
							border-radius: 100%;
							font-size: 1rem;
							color: var(--white);
						}
					}
				}

				div {
					width: 100%;

					background-color: var(--c1);
					border-radius: 0.375rem;
					padding: 0 2rem 2rem;

					.image-title {
						text-align: center;
						font-size: 1.2rem;
						font-weight: bold;
						color: var(--c1-dark);
						line-height: 1.4rem;
						margin-bottom: 0.9375rem;
						background-size: contain !important;
						padding: 1.5rem;
						position: relative;
						top: -1.25rem;
						margin-bottom: -0.625rem;
					}
				}
			}
		}

		.card-learning {
			background-color: var(--c3);
			padding: 1.625rem 1.875rem;

			.learning {
				.trails {
					display: flex;
					flex-wrap: wrap;

					.trail {
						flex: 1 0 30%;
						margin: 0.3rem;
						padding: 0;
						button {
							border-radius: 4px;
							width: 100%;
							height: 5rem;
							vertical-align: middle;
							border: 0;
							font-size: 1rem;
							text-decoration: underline;
							overflow: hidden;
							font-weight: 400;
							text-overflow: ellipsis;
						} &:hover {
							opacity: 0.8;
						}
					}
				}
			}
		}
	}

	button {
		&.btn-video-modal {
			margin: 0 auto !important;
			border: none !important;
			color: var(--text) !important;
			background-color: var(--c1) !important;

			&:focus,
			&:hover {
				color: var(--text) !important;
				background-color: var(--c1) !important;
			}
		}
	}
}

.action-print {
	color: var(--c1);
	background-color: var(--c2);
	border: solid 0.0625rem;
	border-color: var(--c2);
}

@media (max-width: 1020px) {
	.trail {
		flex: 0;
		width: 100%;
		display: block;
	}
}
@media (max-width: 64rem) {
	.CardGradesResources {
		.aprendizado {
			display: inline-grid !important;
		}

		.square {
			.content {
				display: inline-block;
				flex-flow: column;
				gap: 1.5rem;
				width: 100%;
				grid-template-columns: 1fr 1fr;
			}
		}

		.piorrecurso {
			&.wrapper {
				.introducao {
					display: block;
					flex-direction: column;
					align-items: center;

					.image {
						img {
							width: 4.375rem;
							margin-bottom: 0.625rem;
						}
					}
				}
			}
		}
	}
}
