.DetailedSearchAdmin {
	padding: 1rem;

	input.search,
	input.filter {
	  background: #ffffff;
	  border: 1px solid rgba(23, 17, 32, 0.3);
	  border-radius: 12px;
	  width: 100%;
	  height: 40px;
	  padding-left: 10px;
	}
  
	button.search,
	button.filter {
	  border-radius: 8px;
	}
  
	.btn-secondary.filter {
	  // width: 100%;
	}

	.search-area{
		margin-top: 60px;
		margin-bottom: 40px;

		input {
			background: #ffffff;
			border-radius: 12px;
			width: 100%;
			height: 40px;
			border: 1px solid black;
			max-width: 40rem;
			margin-right: 1.5rem;
			padding-left: 2%;
			color: var(--black) !important;
		}

		button {
		  border-radius: 8px;
		  background: #ffffff;
		  border-radius: 12px;
		  height: 40px;
		  border: 1px solid black;
		  padding-left: .9rem;
		  padding-right: .9rem;
		  font-weight: 100;
		}
	  
		.btn-secondary {
			font-family: 'Roboto Flex' !important;
			font-style: normal ;
			font-weight: 100;
			line-height: 16px;
			height: 40px;
			font-size: 15px;
			border: 1px solid black;
			margin-bottom: 2px;
			padding-left: 1.5rem;
			padding-right: 1.59rem;
		}
	}
}