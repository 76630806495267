.CardPerformancePanel {
	display: flex;
	flex-flow: wrap row;
	justify-content: center;
	align-items: stretch;
	gap: 1.5rem;

	.opacity-disabled {
		opacity: 0.7;
	}

	.group {
		width: 32%;
		min-height: 20rem;
		padding: 1.375rem 1.375rem;
		display: flex;
		flex-flow: wrap column;
		gap: 1.5rem;
		border-radius: 0.625rem;
		font-size: 1.125rem;
		color: var(--text);
		background: var(--c3);

		.button {
			width: 100%;
			margin: auto 0 0 0;
			display: flex !important;
			justify-content: flex-end;

			.btn-button {
				width: 7.75rem;
				height: 2.125rem;
				border-radius: 0.25rem;
				border: none;
				font-size: 1.125rem;
				font-weight: 600;
				color: var(--c1);
				background: var(--c2);
			}
		}
	}
}

@media (max-width: 64rem) {
	.CardPerformancePanel {
		.group {
			width: 100%;
			gap: 1rem;
			min-height: auto;

			.title {
				width: 100%;
			}
		}
	}
}
