.modal-macro-goals-add {
	.modal-backdrop {
		&.show {
			opacity: 1 !important;
			background-color: rgba(0, 0, 0, 0.75);
			backdrop-filter: grayscale(100%) blur(0.3125rem);
		}
	}

	.modal-title,
	.title {
		width: 100%;
		margin: 0;
		line-height: 1.25;
		text-align: left;
	}

	.title {
		font-size: 1.1rem;
		color: var(--text) !important;

		&.p {
			font-size: 1.2rem;
		}

		&.t {
			font-size: 1rem;
		}
	}

	.modal-content {
		background-color: var(--c1) !important;
	}

	.modal-header {
		padding: 1.5rem !important;
		align-items: stretch;
		justify-content: flex-start;
		gap: 1rem;
		color: var(--c1) !important;

		.modal-title {
			width: unset;
			flex-basis: 0;
			flex-grow: 1;
			flex-shrink: 1;
			font-size: 1.3rem !important;
			color: var(--c1) !important;
		}

		.close > span:not(.sr-only) {
			height: 100%;
			margin: 0 !important;
			padding: 0 !important;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--c1) !important;

			* {
				pointer-events: none;
			}
		}
	}

	.modal-body {
		padding: 1.5rem 2.5rem !important;
		display: flex;
		flex-flow: wrap column;
		gap: 1rem;
		color: var(--white);

		.fieldset {
			width: 100%;
			display: flex;
			flex-flow: wrap column;
			justify-content: flex-start;
			gap: 0.75rem;

			label {
				width: 100%;
				margin: 0;
				padding: 0;
				position: relative;
				display: inline-block;
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				user-select: none;
				cursor: pointer;
				font-size: 1rem;
			}

			.error {
				font-size: 0.875rem;
				text-align: left;
				top: -0.625rem;
				color: #c54f4f;
				margin-bottom: 0.1875rem;
			}

			input {
				margin: 0;
			}

			input,
			select,
			.input,
			.select {
				width: 100%;
				max-width: 100%;
				padding: 0.75rem 1rem;
				border-radius: 0.5rem;
				border: 0.125rem solid var(--c3);
				font-size: 1rem;
				color: var(--text) !important;
				caret-color: var(--white) !important;
				background-color: var(--c1) !important;
				transition: all 0.35s ease-in;
				max-height: 3.625rem;

				&:focus {
					border-color: var(--c3);
					background-color: var(--c1) !important;
				}

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					color: var(--white) !important;
					-webkit-text-fill-color: var(--white) !important;
					background-color: var(--c1) !important;
					box-shadow: 0 0 0 1.875em var(--c1) inset !important;
				}

				&:disabled {
					opacity: 0.3;
				}

				&.invalid {
					border: 0.125rem solid #c54f4f;
				}
			}

			.content-selfie {
				flex-flow: row;

				.view-selfie {
					text-align: center;
					position: relative;
					top: -0.625rem;
					width: 4.6875rem;
					height: 4.6875rem;
					border-radius: 100%;
					border: 0.125rem solid #fff;
					padding: 0.1875rem;
					margin-left: 0.9375rem;

					img {
						width: 4.0625rem;
						height: 4.0625rem;
						border-radius: 100%;
					}
				}
			}

			.select {
				padding: 0;

				&.disabled {
					opacity: 0.4;
				}
			}

			.help-text {
				width: 100%;
				display: inline-block;
				user-select: none;
				font-size: 0.75rem;
			}

			&.toggle {
				flex-flow: wrap row;
				align-items: center;

				label.toggle {
					display: flex;
					flex-flow: wrap row;
					align-items: center;
					gap: 1rem;

					input[type="checkbox"].toggle-input {
						width: 0;
						height: 0;
						margin: 0;
						position: absolute;
						visibility: hidden;

						&:checked {
							& ~ .toggle-display {
								&::before {
									transform: translate(100%, -50%);
								}
							}
						}
					}

					.toggle-display {
						--offset: 0.25em;
						--diameter: 1.75em;

						width: calc(var(--diameter) * 2 + var(--offset) * 2);
						height: calc(var(--diameter) + var(--offset) * 2);
						box-sizing: content-box;
						position: relative;
						display: inline-flex;
						align-items: center;
						justify-content: space-around;
						border: none;
						border-radius: 100vw;
						font-size: 1em;
						background-color: var(--c3);
						transition: all 250ms;

						.toggle-icon {
							display: inline-block;
							vertical-align: middle;
							overflow: hidden;
							color: inherit;
							fill: currentcolor;

							&.checked {
								color: var(--c1);
							}

							&.not-checked {
								color: var(--c6);
							}
						}

						&::before {
							content: "";
							width: var(--diameter);
							height: var(--diameter);
							box-sizing: border-box;
							will-change: transform;
							position: absolute;
							transform: translate(0, -50%);
							top: 50%;
							left: var(--offset);
							z-index: 2;
							border-radius: 50%;
							background-color: var(--white);
							transition: inherit;
						}
					}

					&:focus,
					&:focus-within {
						outline: 0.0625rem dotted var(--white);
						outline: 0.0625rem auto -webkit-focus-ring-color;
						outline-offset: 0.125rem;
					}
				}
			}
		}
	}
}
