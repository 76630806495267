@charset "UTF-8";
@use "sass:math";

[color-scheme="dark"] {
	.progress-tracker-item {
		.progress-tracker-item-row {
			&.title-row {
				background-color: #161515;
			}
		}

		.progress-tracker-item-col {
			.content {
				color: var(--white);
			}
		}
	}

	.ProgressTrackerSubItem {
		.card {
			.content-row {
				.content {
					color: var(--white) !important;
				}
			}
		}
	}
}

.progress-tracker-item {
	width: 100%;
	height: 100%;
	position: relative;

	&.card {
		background-color: var(--c14);
		padding: 0;
		box-shadow: none;
	}

	.form-check {
		width: 100%;
		max-width: 100%;
		margin: 0 !important;
		padding: 0 !important;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: nowrap row;
		gap: 0;
	}

	.input-wrapper {
		width: 4rem;
		max-width: 0;
		height: 100%;
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}

	label,
	.sub-item {
		width: 100%;
		color: var(--white) !important;
		display: flex;
		flex-flow: row;
		gap: 1.25rem;
		margin: 0;
	}

	.card {
		width: 100%;
		margin: 0;
		padding: 0;
		position: relative;
		transform: translateY(0);
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0;
		background: none;
		border: none;
	}

	.progress-tracker-item-row {
		width: 100%;
		padding: 0.5rem 1.5rem 0.5rem;
		display: flex;
		flex-flow: wrap row;
		align-items: center;
		justify-content: center;
		gap: 2rem;

		&.title-row {
			padding: 0.5rem 1.5rem 0.5rem;
			border-radius: 0.375rem 0.375rem 0 0;
			background: var(--sidenav);
		}

		&.content-row {
			border-radius: 0 0 0.375rem 0.375rem;
			background-color: var(--c3);

			&:last-child {
				border-bottom: none;
			}
		}

		&.is-gapless {
			gap: 0;
		}
	}

	.progress-tracker-item-col {
		height: 100%;
		padding: 0.375rem;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: center;
		gap: 1rem;

		.content {
			width: 100%;
			padding: 0.5rem 0 0.5rem;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			font-size: 1.125rem;
			font-weight: 400;
			color: var(--c3-dark);

			.btn-open {
				margin-right: 0.625rem;
				cursor: pointer;
			}
		}

		@for $i from 0 through 12 {
			&.is-#{$i} {
				flex: none;
				width: percentage(math.div($i, 12));
			}
		}

		button {
			width: 100%;
			margin: 0 0 0 auto;
			box-shadow: none !important;
		}
	}

	.title {
		width: 100%;
		line-height: 1.5;
		text-align: left;
		font-size: 1.6rem;
		font-weight: 600;
	}

	.mb-label {
		width: 100%;
		display: none;
		text-align: left;
		font-family: "Futura PT", sans-serif !important;
		font-size: 1.25rem;
		font-weight: 700;
	}

	&.uelf {
		.card {
			background-color: var(--c3);

			.title-row {
				.title {
					color: var(--white);
				}
			}

			.content-row {
				&:not(.border-bottom) {
					background-color: var(--c2);
				}

				&:last-child {
					border: none !important;
				}

				.content {
					color: var(--c1);
				}
			}
		}

		.ProgressTrackerSubItem {
			.card {
				.content-row {
					.content {
						color: var(--c3-dark);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 70rem) {
	.progress-tracker-item {
		.progress-tracker-item-row {
			flex-flow: wrap column;

			&.title-row {
				display: none;
			}

			&.content-row {
				border-radius: 0.375rem;
			}
		}

		.progress-tracker-item-col {
			width: 100%;

			@for $i from 0 through 12 {
				&.is-#{$i} {
					width: 100%;
				}
			}
		}

		.sub-item {
			flex-flow: wrap column;

			.card {
				gap: 1rem;
			}
		}

		.charts {
			.card-body {
				.items-wrapper {
					flex-flow: wrap column;

					div {
						&:nth-child(2) {
							width: 100%;
						}
					}
				}
			}
		}

		.card {
			.mb-label {
				display: block;
			}
		}
	}
}
