.LoginAdmin {
  font-family: "Roboto", sans-serif !important;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  color: black !important;
  
  .container {
    max-width: 1320px;
  }

  .column:nth-child(2) {
    background-color: white;
    border-radius: 20px;
    padding: 3rem;
    
    h1 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: black !important;
    }
    p {
      text-align: center;
    }

    label {
      font-weight: bold;
      color: black!important;
    }
  }

  form > div {
    margin-bottom: 0 !important;
    padding: 0;

    input, input.true {
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 0.375rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    input:focus, input.true:focus {
      color: #212529 !important;
      background-color: #fff !important;
      border-color: #86b7fe !important;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }
  }

  .btn-primary {
    width: 100%;
  }

  .form-text, .text-muted {
    color: black !important;
    text-align: center !important;
    width: 100% !important;
    margin: 0 !important;
  }

  .btn-primary {
    box-sizing: border-box !important;
    // width: 100% !important;
    background: #99da35 !important;
    border: 1px solid #bbdf84 !important;
    border-radius: 4px !important;
    color: black !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Roboto", sans-serif !important;
  }
  
  .btn-primary:hover {
    background: #99da35 !important;
    opacity: 0.8;
  }
  
  .btn-primary:disabled {
    background: #99da35 !important;
    opacity: 0.6 !important;
    border: 1px solid #bbdf84 !important;
  }
  
  .btn-primary:hover {
    border: 1px solid #fff !important;
  }
}
