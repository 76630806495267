.diagnose {
	--plyr-color-main: var(--c2);

	.tutorial {
		.card {
			margin-top: 1.625rem;
			padding: 1.625rem 1.875rem;
			gap: 2rem;
			border-radius: 0.75rem;
			background-color: var(--c3);

			.title {
				font-size: 1.625rem;
				font-weight: 600;
				color: var(--text);
			}
		}
	}

	.card-inverse {
		background-color: var(--c3);
	}
}
