.to-do-avoid {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 2.625rem;
	color: var(--text);

	.content {
		.list {
			.item {
				.label {
					margin: 0 auto;
				}
			}
		}
	}
}
