.CalendarComponent {
	display: flex;
	flex-direction: row;
	position: relative;

	.control {
		display: flex;
		flex-flow: wrap column;
		justify-content: center;
		border-radius: 0.9375rem 0 0 0.9375rem;
		background-color: var(--c2);

		span {
			padding: 0.625rem 0.625rem 0;
			word-break: keep-all;
			font-size: 1.25rem;
			font-weight: 700;
			color: var(--c1-dark);
		}

		ul {
			width: 100%;
			margin: 0;
			padding: 0 0.9375rem;
			list-style: none;

			li {
				padding: 0.625rem 0.625rem;
				word-break: keep-all;
				cursor: pointer;
				text-align: center;
				font-size: 1.25rem;
				font-weight: 700;
				color: var(--c1-light);

				&.active {
					border-bottom: 0.25rem solid var(--c1);
				}
			}
		}
	}

	.fc {
		width: 100%;
		background-color: var(--c3);
		border-radius: 0 0.9375rem 0.9375rem 0;
		padding: 1.875rem;

		.fc-scroller {
			overflow: hidden !important;
		}

		td,
		th,
		.fc-scrollgrid {
			border: none !important;
		}

		td,
		th {
			height: 3.75rem;
		}

		.fc-header-toolbar {
			margin-bottom: 0;
			padding: 0.9375rem 1.5625rem 1.25rem;

			.fc-toolbar-title {
				color: var(--text);
				text-transform: capitalize;
			}

			.fc-toolbar-chunk {
				.fc-button {
					display: flex;

					.fc-icon {
						font-size: 1.875rem;
						top: 0;
					}
				}

				div {
					display: flex;
					align-items: center;
					gap: 3.125rem;
				}

				.fc-button-primary {
					color: var(--text);
					border: none;
					background-color: transparent;
					outline: none;
					box-shadow: none;

					.fc-icon:hover {
						color: var(--text);
					}

					&:hover,
					&:active,
					&:focus {
						background-color: transparent !important;
						box-shadow: none !important;
					}
				}
			}
		}

		.fc-footer-toolbar {
			display: block;
			margin-top: 1.3rem;

			.fc-toolbar-chunk {
				width: 100%;

				.fc-button-group {
					display: flex;
					align-items: center;
					gap: 3.125rem;
					justify-content: space-between;
					flex-wrap: nowrap;

					.fc-custom1-button {
						cursor: default;

						&::before {
							content: "";
							display: inline-block;
							width: 0.9375rem;
							height: 0.9375rem;
							-moz-border-radius: 0.4688rem;
							-webkit-border-radius: 0.4688rem;
							border-radius: 0.4688rem;
							background-color: var(--c2);
							position: relative;
							top: 0.125rem;
							margin-right: 0.4375rem;
						}
					}

					.fc-custom2-button {
						cursor: default;

						&::before {
							content: "";
							display: inline-block;
							width: 0.9375rem;
							height: 0.9375rem;
							-moz-border-radius: 0.475rem;
							-webkit-border-radius: 0.4688rem;
							border-radius: 0.4688rem;
							background-color: transparent;
							border: 0.0625rem solid var(--c17);
							position: relative;
							top: 0.125rem;
							margin-right: 0.4375rem;
						}
					}

					.fc-button-primary {
						color: var(--text);
						border: none;
						background-color: transparent;
						outline: none;
						box-shadow: none;
						font-size: 1rem;

						.fc-icon:hover {
							color: var(--text);
						}

						&:hover,
						&:active,
						&:focus {
							background-color: transparent !important;
							box-shadow: none !important;
						}
					}
				}
			}
		}

		.fc-col-header-cell.fc-day {
			text-align: center;

			.fc-col-header-cell-cushion {
				color: var(--text);
				text-align: center;
				cursor: default;
				font-size: 1.25rem;
			}
		}

		.fc-day {
			&.fc-day-other {
				.fc-daygrid-day-frame {
					display: none;
				}
			}

			&.fc-day-today {
				.fc-daygrid-day-top {
					.fc-daygrid-day-number {
						color: var(--c2) !important;
					}
				}
			}

			&.selected-event-emprogresso {
				.fc-daygrid-day-top {
					.fc-daygrid-day-number {
						text-align: center;
						line-height: 1.4rem;
						font-style: normal;
						font-weight: 600;
						background-color: var(--c2);
						margin: 1.125rem auto;
						padding: 1.125rem;
						border-radius: 100%;
						width: 2.8rem;
						height: 2.8rem;
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}

			&.selected-event-atraso {
				.fc-daygrid-day-top {
					.fc-daygrid-day-number {
						text-align: center;
						line-height: 1.4rem;
						font-style: normal;
						font-weight: 600;
						border: 0.0625rem solid var(--c17);
						margin: 1.125rem auto;
						padding: 0.9375rem;
						border-radius: 100%;
						width: 2.8rem;
						height: 2.8rem;
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}

			.fc-daygrid-day-frame {
				height: 100%;

				.fc-daygrid-day-top {
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;

					.fc-daygrid-day-number {
						font-size: 1.4rem;
						color: var(--text) !important;
						cursor: default;
					}
				}

				.fc-daygrid-day-events,
				.fc-daygrid-day-bg {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 90rem) {
	.CalendarComponent {
		flex-direction: column;

		.fc {
			border-radius: 0 0 0.9375rem 0.9375rem;
			padding: 0.625rem;

			.fc-scrollgrid-section {
				table {
					width: 100% !important;
				}
			}

			.fc-daygrid-body {
				width: 100% !important;
			}

			.fc-header-toolbar {
				padding: 0.625rem 1.25rem;

				.fc-toolbar-chunk {
					.fc-button {
						.fc-icon {
							font-size: 1.375rem;
						}
					}
				}
			}

			.fc-col-header-cell {
				&.fc-day {
					.fc-col-header-cell-cushion {
						font-size: 1.2rem;
					}
				}
			}

			.fc-toolbar-title {
				font-size: 1.5em;
			}

			.fc-col-header-cell.fc-day {
				word-break: normal;
			}

			.fc-toolbar.fc-header-toolbar {
				margin-bottom: 0;
			}

			.fc-day {
				.fc-daygrid-day-frame {
					.fc-daygrid-day-top {
						word-break: normal;

						.fc-daygrid-day-number {
							font-size: 1rem;
						}
					}
				}

				&.selected-event-emprogresso {
					.fc-daygrid-day-top {
						.fc-daygrid-day-number {
							padding: 0;
							margin: 0;
							width: 2rem;
							height: 2rem;
						}
					}
				}
			}

			td,
			th {
				height: 2.5rem;
			}
		}

		.control {
			border-radius: 0.9375rem 0.9375rem 0 0;

			ul {
				display: flex;
				flex-direction: row;
				overflow-x: auto;
				width: 100%;

				li {
					word-break: normal;
				}
			}
		}
	}
}
