.CardMapJourney {
	.wrapper {
		display: flex;
		flex-flow: wrap column;
		gap: 1.5rem;
		color: var(--text);

		.card {
			width: 100%;
			position: relative;
			display: flex;
			flex-flow: wrap row;
			align-items: stretch;
			justify-content: center;
			border-radius: 0.625rem;
			background-color: var(--c3);
			gap: 3.25rem;

			.image {
				width: 32.875rem;
				min-height: 21.625rem;
				position: relative;
				overflow: hidden;
				display: flex;
				border-radius: 0 0.625rem 0 0.625rem;

				img,
				svg {
					width: 100%;
					height: 100%;
					border-radius: 0.625rem;
					object-fit: cover;
					object-position: center;
				}
			}

			.content {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				padding: 3rem 3.75rem 3rem 0;
				display: flex;
				flex-flow: wrap column;
				align-items: flex-start;
				justify-content: space-between;
				gap: 4.5rem;

				.description {
					width: 100%;
					display: flex;
					flex-flow: wrap column;
				}

				.actions {
					width: 100%;
					display: flex;
					flex-flow: wrap row;
					justify-content: space-between;
					gap: 2rem;

					.color-letter {
						font-size: 1rem;
						color: var(--heading-light);
					}

					button {
						background-color: var(--c2);
						border-color: var(--c2);
						color: var(--c1-dark) !important;
						padding: 0.4375rem 2.125rem 0.375rem 2.125rem;
						border-radius: 0.375rem;
						font-size: 1.125rem;
						font-weight: 400;
						border: none;

						&:disabled {
							opacity: 0.5;
							filter: grayscale(80%);
						}
					}
				}
			}
		}
	}
}

@media (max-width: 56.25rem) {
	.CardMapJourney {
		.wrapper {
			.card {
				gap: 1.25rem;

				.image {
					width: 100% !important;
					height: 100%;
					min-height: auto;
				}

				.content {
					padding: 0;
					gap: 1.25rem;

					.description {
						display: block;
						padding: 1rem;
						text-align: justify;
					}
				}

				.actions {
					position: relative;
					bottom: 0;
					padding: 1rem;

					.percentage {
						display: flex;
						margin: 0 0 0 0.625rem;
					}
				}
			}
		}
	}
}

@media (max-width: 64rem) {
	.CardMapJourney {
		.wrapper {
			.content {
				align-items: center;
			}
		}
	}
}
