.NotesStage {
	width: 100%;
	display: flex;
	flex-flow: wrap row;
	align-items: stretch;
	justify-content: flex-start;
	gap: 2rem;

	.row {
		width: 100%;
	}

	.notes-item {
		width: 100%;
		max-width: 18rem;
		height: 100%;
		min-height: 15rem;
		max-height: 20rem;
		margin: 0;
		padding: 1rem;
		float: left;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0;
		border-radius: 0.5rem;
		color: var(--c3-dark);
		background-color: #feffb8;

		.item-header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 1rem;

			.item-label-icons {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 1rem;
				font-size: 1.25rem;
				color: var(--c4);

				.label-icon {
					max-width: 1.5rem;
					max-width: 1.5rem;
				}

				.item-title {
					width: 100%;
					font-weight: 700;
				}
			}

			.item-label-emojis {
				i,
				svg {
					color: #8b8a79;
				}

				.active {
					color: var(--c3-dark);
				}
			}
		}

		.item-body {
			width: 100%;
			margin: 0;
			display: flex;
			flex-flow: wrap column;
			align-items: flex-start;
			justify-content: space-between;
			gap: 1rem;

			.goal,
			.note-text {
				width: 100%;
				text-align: left;
			}

			.goal {
				font-size: 1.125rem;
				font-weight: 700;
				color: #8b8a79;
			}

			.note-text {
				padding: 0 1rem;
				font-size: 1rem;
			}
		}

		.note-date {
			width: 100%;
			margin: auto 0 0 0;
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			color: var(--c3);
		}
	}
}

.notes-actions {
	margin-bottom: 2rem;
	width: 100%;
	display: flex;
	gap: 2rem;

	.items {
		width: 100%;
	}

	button {
		margin-bottom: 0.5rem;
		color: var(--white);
		border-color: var(--c2);
		background-color: var(--c2);

		&:focus,
		&:focus-within,
		&:hover,
		&:active {
			border-color: var(--c2);
			background-color: var(--c1);
		}

		&.floating-button {
			position: fixed;
			bottom: 2rem;
			right: 2rem;
			z-index: 1;
		}
	}
}

@media (max-width: 38rem) {
	.NotesStage {
		.notes-item {
			max-width: 32rem;
			max-height: 30rem;
			margin: 0 auto;
		}
	}
}

@media (max-width: 54.98rem) {
	.notes-actions {
		justify-content: center;

		.actions {
			width: 100%;
			max-width: 32rem;
			display: flex;
			flex-direction: column;

			button {
				margin: 0 0 0.5rem 0;
			}
		}
	}
}

@media (min-width: 100rem) {
	.NotesStage {
		.notes-item {
			max-width: 20rem;
		}
	}
}
