.ModalProfileConfirmation {
    form {

        input,
        select,
        .input,
        .select {
            width: 100%;
            max-width: 100%;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;
            border: 0.125rem solid var(--white);
            font-size: 0.875rem;
            color: var(--white) !important;
            caret-color: var(--white) !important;
            background-color: var(--c1) !important;
            transition: all 0.35s ease-in;
            max-height: 3.625rem;

            &:focus {
                border-color: var(--c1);
                background-color: var(--c3) !important;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                color: var(--white) !important;
                -webkit-text-fill-color: var(--white) !important;
                background-color: var(--c2) !important;
                box-shadow: 0 0 0 1.875em var(--c2) inset !important;
            }

            &:disabled {
                opacity: 0.3;
            }

            &.invalid {
                border: 0.125rem solid #c54f4f;
            }
        }
    }

}